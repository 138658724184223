import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';
import { Options } from 'ng5-slider';
import { interval, Subscription } from 'rxjs';


declare var $: any


declare var Slider: any;
@Component({
  selector: 'app-device-dashboard',
  templateUrl: './device-dashboard.component.html',
  styleUrls: ['./device-dashboard.component.css']
})
export class DeviceDashboardComponent implements OnInit, OnDestroy {
  userType: any;
  userRwPermission: any;
  finalRwPermission: any;
  maxRole: any;
  thingsName: any;
  thing: any;
  thingsDetails: any;
  roleName: any;
  roleList = []
  sliderValue: number = 15;
  maxSetPointValue: number = 40;
  minSetPointValue: number = 15;
  setbackSetpointValue: number;
  sliderOptions: Options = {
    ceil: 40,
    floor: 15,
    step: 0.5,
    maxLimit: 40,
    minLimit: 15,
    showSelectionBar: true,
    ticksArray: []
  };
  subscription: Subscription;
  thingStorage: any;
  alarmCounter: any;
  thingsArr: any = [];
  customer: any;
  site: any;
  country: any;
  city: any;
  room: any;
  unit: any;
  SN: any;
  device_user: any;

  constructor(private router: Router, private route: ActivatedRoute, private service: UserDataService) {
    var serialNumber = JSON.parse(localStorage.getItem('allThingsName'))
    this.thing = serialNumber[0].thingname;
  }



  ngOnInit() {
    this.userType = localStorage.getItem('userType')
    let data = {
      "function_name": "get_role",
    }
    this.service.postApi("/get-role", data).subscribe((res) => {
      this.roleName = res['body']
      for (let x in this.roleName) {
        this.roleList.push(this.roleName[x]['rw'])
      }
      this.maxRole = Math.max.apply(null, this.roleList)
      this.userRwPermission = localStorage.getItem('userAccess')
      this.finalRwPermission = Math.min(this.maxRole, this.userRwPermission)
    }, (err) => {
    })
    this.getDeviceDetails();
    this.getAlarmCounter();
    const source = interval(10000);
    this.subscription = source.subscribe(val => this.getDeviceDetails());
  }


  // +++++++++++++++++++++++++++  Select value of variable and navigate on dashboard   ++++++++++++++++++++++++++++

  dashboard(site) {
    this.site = site.toLowerCase();
    localStorage.setItem('site', this.site);
    this.router.navigate(['/dashboard']);
  }
  dashboard1(customer) {
    this.customer = customer.toLowerCase();
    localStorage.setItem('customer', this.customer);
    this.router.navigate(['/dashboard']);
  }
  dashboard2(country) {
    this.country = country.toLowerCase();
    localStorage.setItem('country', this.country);
    this.router.navigate(['/dashboard']);
  }
  dashboard3(city) {
    this.city = city.toLowerCase();
    localStorage.setItem('city', this.city);
    this.router.navigate(['/dashboard']);
  }
  dashboard4(room) {
    this.room = room.toLowerCase();
    localStorage.setItem('room', this.room);
    this.router.navigate(['/dashboard']);
  }
  dashboard5(unit) {
    localStorage.setItem('unit', unit);
    this.router.navigate(['/dashboard']);
  }
  dashboard6(SN) {
    localStorage.setItem('SN', SN);
    this.router.navigate(['/dashboard']);
  }
  // This Code by Amit Sharma
  dashboard7(device_user) {
    this.device_user = device_user.toLowerCase();
    localStorage.setItem('device_user', this.device_user);
    this.router.navigate(['/dashboard']);
  }

  // +++++++++++++++++++++++++++  get device Details   ++++++++++++++++++++++++++++

  getDeviceDetails() {
    let data = {
      "function_name": "get_thing_shadow_new",
      "thingnames": [{ "thingname": this.thing }]
    }
    this.service.postApi("/get-thing-shadow-new", data).subscribe((res) => {
      if (res['body']['data'].length != 0) {

        this.thingsDetails = res['body']['data'][0];
        this.customer = this.thingsDetails.customer.toUpperCase();
        this.device_user = this.thingsDetails.user.toUpperCase(); // This code by Amit Sharma
        this.country = this.thingsDetails.country.toUpperCase();
        this.city = this.thingsDetails.city.toUpperCase();
        this.site = this.thingsDetails.site.toUpperCase();
        this.room = this.thingsDetails.room.toUpperCase();
        this.minSetPointValue = parseInt(this.thingsDetails.minSetpoint)
        this.maxSetPointValue = parseInt(this.thingsDetails.maxSetpoint)
        this.sliderValue = parseInt(this.thingsDetails.normalSetpoint)
        this.setbackSetpointValue = parseInt(this.thingsDetails.setbackSetpoint)
        this.sliderOptions = {
          ceil: this.maxSetPointValue + 1,
          floor: this.minSetPointValue - 1,
          showSelectionBar: true,
          maxLimit: this.setbackSetpointValue,
          minLimit: this.minSetPointValue,
          step: 0.5,
          ticksArray: [this.minSetPointValue, this.maxSetPointValue]
        };
        localStorage.setItem('airconSerial', this.thingsDetails.airconSerial)
      } else {
        this.service.error("There is no data ")
      }
    }, (err) => {
    })
  }

  // +++++++++++++++++++++++++++  Change Thermostat Mode   ++++++++++++++++++++++++++++

  changeMode(val) {
    var thermostatModeVal = val;
    if (thermostatModeVal == 'STANDBY') {
      thermostatModeVal = 'NORMAL'
    } else {
      thermostatModeVal = 'STANDBY'
    }
    let data = {
      "update_device": {
        "thermostatMode": thermostatModeVal
      },
      "thingnames": this.thing,
      "function_name": "update_thing_shadow_new"
    }
    this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
      if (res['body']['responseMessage'] == "You don't have write permission..") {
        this.service.error(res['body']['responseMessage'])
      } else if (res['body']['responseMessage'] == "Your device seems to be offline.") {
        this.service.error(res['body']['responseMessage'])
      } else {
        this.thingsDetails.thermostatMode = thermostatModeVal
      }
    }, (error) => {
    })
  }

  // +++++++++++++++++++++++++++  Change slider   ++++++++++++++++++++++++++++

  sliderChangeApi() {
    var dataValue = this.sliderValue
    let data = {
      "update_device": {
        "normalSetpoint": dataValue.toFixed(1)
      },
      "thingnames": this.thing,
      "function_name": "update_thing_shadow_new"
    }
    this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
      if (res['body']['responseMessage'] == "You don't have write permission..") {
        this.service.error(res['body']['responseMessage'])
      } else if (res['body']['responseMessage'] == "Your device seems to be offline.") {
        this.service.error(res['body']['responseMessage'])
      }
    }, (error) => {
    })
  }


  checkValueApi() {
    setTimeout(() => {
      this.sliderChangeApi();
    }, 100)
  }
  
  // +++++++++++++++++++++++++++ manage data and integration of get Alarm Counter api  ++++++++++++++++++++++++++++
  getAlarmCounter() {
    this.thingStorage = localStorage.getItem('thingStorage')
    let data = {
      "function_name": "device_alarm_status_new",
      "thingname": this.thingStorage
    }
    this.service.postApi("/device-alarm-status-new", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.thingsDetails = res['body']['data']
        var alarmCounter = [];
        this.thingsDetails.forEach(obj => {
          if (obj[2] == '1') {
            if (obj[1][1] == 'Alarm Major') {
              alarmCounter.push(obj[1][0])
            } else if (obj[1][1] == 'Alarm Minor') {
              alarmCounter.push(obj[1][0])
            }
          }
          this.alarmCounter = alarmCounter.length;
        })
      } else {
        this.service.error("There is no data ")
      }
    }, (err) => {
    })
  }

  //view Dismiss
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
