import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
declare var $: any;


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  isTrue: boolean = false
  otpURl: any
  resetForm: FormGroup;
  optValue: any
  constructor(private router: Router, private route: ActivatedRoute, private service: UserDataService, private spinner: NgxSpinnerService) {
    this.resetForm = new FormGroup({
      newPassword: new FormControl('', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.minLength(8), Validators.required]),
      cPassword: new FormControl('', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.minLength(8), Validators.required]),
      mobileOtp1: new FormControl(''),
      mobileOtp2: new FormControl(''),
      mobileOtp3: new FormControl(''),
      mobileOtp4: new FormControl(''),
      mobileOtp5: new FormControl(''),
      mobileOtp6: new FormControl(''),
    }, passwordMatchValidator);
    function passwordMatchValidator(matchPassword: FormGroup) {
      return matchPassword.get('newPassword').value === matchPassword.get('cPassword').value ? null : { mismatch: true };
    }
  }
  ngOnInit() {
    setTimeout(() => {
      $(document).ready(function () {
        $('input').keyup(function (e) {
          if (e.keyCode == 8) {
            $(this).prev().focus();
          } else if ($(this).val().length == $(this).attr("maxlength")) {
            $(this).next().focus();
          }
        });
      });
    }, 200)
  }

  //manage reset passwrd api
  resetPasswordSubmit() {
    this.spinner.show();
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem('username'),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    let mobileOtpValue = this.resetForm.value.mobileOtp1 + '' + this.resetForm.value.mobileOtp2 + '' + this.resetForm.value.mobileOtp3 + '' + this.resetForm.value.mobileOtp4 + '' + this.resetForm.value.mobileOtp5 + '' + this.resetForm.value.mobileOtp6
    this.otpURl = mobileOtpValue
    var self = this;
    cognitoUser.confirmPassword(this.otpURl, this.resetForm.value.cPassword, {
      onSuccess: () => {
        this.spinner.hide();
        self.service.success('Password changed successfully')
        this.router.navigate(['/login'])
      },
      onFailure: (err) => {
        this.spinner.hide();
        if (err.message == "Invalid verification code provided, please try again.") {
          this.service.error("Please provide valid OTP")
        } else {
          this.service.error("Something Went wrong")
        }
      }

    });
  }

  //integration of resend otp 
  resendOtpForgot() {
    this.spinner.show();
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem('username'),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        this.spinner.hide();
        this.service.success('OTP sent to your mobile number')
      },
      onFailure: (err) => {
        this.spinner.hide();
        if (err.message == 'the response body from fetch is: undefined') {
          this.service.success('OTP sent to your mobile number')
        } else {
          this.service.error(err.message);
        }
      }
    });
  }
}
