import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { UserDataService } from '../../provider/user-data.service';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
    obj = {}
    is_email_alert = false
    is_app_alert = false
    profileData: any
    currPage: number = 1
    userResponse: any
    userDetails: any
    profileId: any;
    result: any = [];
    profileObj: any = {}
    constructor(private route: ActivatedRoute, private service: UserDataService) {
    }
    ngOnInit() {
        this.profileId = this.route.params.subscribe(params => console.log(params.id));
        this.userData();
    }

    //integration of user get profile api
    userData() {
        var data = {
            UserPoolId: 'us-east-1_FBju9grOp',
            ClientId: '73itd0a6o426vup20afs3aqdna'
        };
        var userPool = new CognitoUserPool(data);
        let cognitoUser = userPool.getCurrentUser();
        if (cognitoUser != null) {
            var self = this;
            cognitoUser.getSession(function (err, session) {
                cognitoUser.getUserAttributes(function (err, result) {
                    if (err) {
                    } else {
                        var data = []
                        let obj = {};
                        for (let i in result) {
                            obj[result[i]['Name']] = result[i]['Value'];
                        }
                        self.profileObj = obj;
                        self.is_email_alert = self.profileObj['custom:is_email_alert'] == '1' ? true : false;
                        self.is_app_alert = self.profileObj['custom:is_app_alert'] == '1' ? true : false;
                        self.result = result;
                    }
                });

            });
        }

    }

}
