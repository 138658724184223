import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selected-field',
  templateUrl: './selected-field.component.html',
  styleUrls: ['./selected-field.component.css']
})
export class SelectedFieldComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
