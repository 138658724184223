import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  is_read_write = false
  is_app_alert = false
  is_email_alert = false
  id: any;
  isEmailAlert: any;
  email: any
  isAppAlert: any
  userData: any;
  profileData = {}
  profileObj: any = {}
  roles: any = []

  constructor(private router: Router, private route: ActivatedRoute, private service: UserDataService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params
      this.getUserDetails()
    })
  }

  //get data from api and manage data
  getUserDetails() {
    let data =
    {
      "function_name": "admin_get_user",
      "username": this.id.email
    }
    this.service.postApi("/admin-get-user", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        var self = this
        this.userData = res['body']['data']['UserAttributes']
        let obj = {};
        for (let i in this.userData) {
          obj[this.userData[i]['Name']] = this.userData[i]['Value'];
        }
        self.profileObj = obj
        this.roles = res['body']['data']['roles']
        this.isEmailAlert = self.profileObj['custom:is_email_alert']
        this.isAppAlert = self.profileObj['custom:is_app_alert']
        this.is_app_alert = self.profileObj['custom:is_app_alert'] == '1' ? true : false
        this.is_email_alert = self.profileObj['custom:is_email_alert'] == '1' ? true : false
        this.is_read_write = self.profileObj['custom:rw'] == '2' ? true : false
      }
    }, (err) => {
    })

  }


}
