import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { IMyDpOptions, IMyDefaultMonth } from 'mydatepicker';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';



@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  updateDate: any
  formateDate: any
  dateVal: any
  value: any
  is_app_alert: any
  is_email_alert: any
  dateValue: any = {}
  profileObj: {};
  checkbox: any;
  result: any;
  profileId: any;
  editProfileForm: any;
  editProfileObj: any
  firstName: any;
  lastName: any;
  email: any;
  companyName: any;
  isEmailAlert: any;
  jobTitle: any;
  adminCode: any;
  dob: any;
  isAppAlert: any;
  phoneNumber: any;
  countryListJson: any = [];
  typeOfUser: any;
  now: Date = new Date();

  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: { year: this.now.getFullYear() - 16, month: this.now.getMonth() + 1, day: this.now.getDate() + 1 },
    showTodayBtn: false,
    monthSelector: true,
    yearSelector: true,
  };
  defaultMonth: IMyDefaultMonth = {
    defMonth: (this.now.getFullYear() - 16) + '/' + (this.now.getMonth() + 1)
  }

  constructor(private route: ActivatedRoute, private service: UserDataService, private router: Router, private spinner: NgxSpinnerService) {
    var countryList = [];
    this.typeOfUser = localStorage.getItem('userType')
    this.service.countryListJson.forEach(element => {
      countryList.push(element.name)
    });
    this.countryListJson = countryList;
    this.editProfileForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i), Validators.minLength(2), Validators.required]),
      lastName: new FormControl('', [Validators.pattern(/^[a-zA-Z ]*$/i), Validators.minLength(2), Validators.required]),
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required]),
      companyName: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Za-z0-9 _.\-]*$/), Validators.minLength(2), Validators.required]),
      jobTitle: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(2), Validators.required]),
      country: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9 _.\-]*$/), Validators.minLength(2), Validators.required]),
      adminCode: new FormControl('', [Validators.pattern(/^[A-Za-z0-9\-]*$/), Validators.minLength(4), Validators.required]),
      DOB: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.pattern(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/), Validators.minLength(8) ,Validators.required]),
      isAppAlert: new FormControl(''),
      isEmailAlert: new FormControl('')
    })
  }
  ngOnInit() {
    this.userData();
  }

  //manage date format
  dateChange(val) {
    this.updateDate = val.formatted
  }

  //manage get user data
  userData() {
    this.spinner.show();
    var data = {
      UserPoolId: 'us-east-1_FBju9grOp',
      ClientId: '73itd0a6o426vup20afs3aqdna'
    };
    var userPool = new CognitoUserPool(data);
    let cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      var self = this;
      cognitoUser.getSession(function (err, session) {
        cognitoUser.getUserAttributes(function (err, result) {
          self.spinner.hide();
          if (err) {
          } else {
            var data = []
            let obj = {};
            for (let i in result) {
              obj[result[i]['Name']] = result[i]['Value'];
            }
            self.profileObj = obj
            self.setFormValue();
          }
        });
      });
    }
  }

  //set form value using patch
  setFormValue() {
    var dateValue = new Date(this.profileObj['custom:date_of_birth'])
    this.editProfileForm.patchValue({
      firstName: this.profileObj['custom:first_name'],
      lastName: this.profileObj['custom:last_name'],
      companyName: this.profileObj['custom:company_name'],
      jobTitle: this.profileObj['custom:job_title'],
      adminCode: this.profileObj['custom:parent_code'],
      phoneNumber: this.profileObj['custom:phone_number'],
      email: this.profileObj['email'],
      isAppAlert: this.profileObj['custom:is_email_alert'] == 1 ? true : false,
      isEmailAlert: this.profileObj['custom:is_app_alert'] == 1 ? true : false,
      country: this.profileObj['custom:country_name'],
      city: this.profileObj['custom:city_name'],
    })
    if (this.typeOfUser == '4') {
      this.editProfileForm.patchValue({
        DOB: { date: { year: dateValue.getFullYear(), month: dateValue.getMonth() + 1, day: dateValue.getDate() } }
      })
    } else {
      this.editProfileForm.patchValue({
        DOB: this.profileObj['custom:date_of_birth']
      })
    }
  }

  // update user profile 
  updateProfile() {
    this.spinner.show();
    var data = {
      UserPoolId: 'us-east-1_FBju9grOp',
      ClientId: '73itd0a6o426vup20afs3aqdna'
    };
    var userPool = new CognitoUserPool(data);
    let cognitoUser = userPool.getCurrentUser();
    var attributeList = [];
    var dataPhoneNumber = {
      Name: 'custom:phone_number',
      Value: this.editProfileForm.value.phoneNumber,
    };
    var firstName = {
      Name: 'custom:first_name',
      Value: this.editProfileForm.value.firstName,
    };
    var lastName = {
      Name: 'custom:last_name',
      Value: this.editProfileForm.value.lastName,
    };
    if (this.typeOfUser == '4') {
      var dateOfBirth = {
        Name: 'custom:date_of_birth',
        Value: this.editProfileForm.value.DOB.formatted ? this.editProfileForm.value.DOB.formatted : this.editProfileForm.value.DOB.date.year + '-' + this.editProfileForm.value.DOB.date.month + '-' + this.editProfileForm.value.DOB.date.day,
      };
    }
    var jobTitle = {
      Name: 'custom:job_title',
      Value: this.editProfileForm.value.jobTitle,
    };
    var appAlert = {
      Name: 'custom:is_app_alert',
      Value: this.is_app_alert == true ? '1' : '0',
    };
    var emailAlert = {
      Name: 'custom:is_email_alert',
      Value: this.is_email_alert == true ? '1' : '0',
    };
    var adminCode = {
      Name: 'custom:admin_code',
      Value: this.editProfileForm.value.adminCode,
    };
    var parentCode = {
      Name: 'custom:parent_code',
      Value: this.editProfileForm.value.adminCode,
    };
    var companyName = {
      Name: 'custom:company_name',
      Value: this.editProfileForm.value.companyName,
    };
    var country = {
      Name: 'custom:country_name',
      Value: this.editProfileForm.value.country,
    };
    var city = {
      Name: 'custom:city_name',
      Value: this.editProfileForm.value.city,
    };

    var dataPhoneNumberAttribute = new CognitoUserAttribute(dataPhoneNumber);
    var firstNameAttribute = new CognitoUserAttribute(firstName);
    var lastNameAttribute = new CognitoUserAttribute(lastName);
    if (this.typeOfUser == '4') {
      var dateOfBirthAttribute = new CognitoUserAttribute(dateOfBirth);
    }
    var jobTitleAttribute = new CognitoUserAttribute(jobTitle);
    var appAlertAttribute = new CognitoUserAttribute(appAlert);
    var emailAlertAttribute = new CognitoUserAttribute(emailAlert);
    var adminCodeAttribute = new CognitoUserAttribute(adminCode);
    var parentCodeAttribute = new CognitoUserAttribute(parentCode);
    var companyNameAttribute = new CognitoUserAttribute(companyName);
    var countryAttribute = new CognitoUserAttribute(country);
    var cityAttribute = new CognitoUserAttribute(city);

    if (this.typeOfUser == '4') {
      attributeList.push(dataPhoneNumberAttribute);
      attributeList.push(firstNameAttribute);
      attributeList.push(lastNameAttribute);
      attributeList.push(dateOfBirthAttribute);
      attributeList.push(jobTitleAttribute);
      attributeList.push(appAlertAttribute);
      attributeList.push(emailAlertAttribute);
      attributeList.push(adminCodeAttribute);
      attributeList.push(parentCodeAttribute);
      attributeList.push(companyNameAttribute);
      attributeList.push(countryAttribute);
      attributeList.push(cityAttribute);
    } else {
      attributeList.push(jobTitleAttribute);
      attributeList.push(appAlertAttribute);
      attributeList.push(emailAlertAttribute);
    }
    var self = this
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          self.spinner.hide();
          self.service.error('Something Went Wrong')
        } else {
          cognitoUser.updateAttributes(attributeList, function (err, result) {
            self.spinner.hide();
            if (result == "SUCCESS") {
              self.service.success('Profile updated successfully')
              self.router.navigate(['/my-profile'])
            } else {
              self.service.error('Something Went Wrong')
            }
          });
        }
      });
    }
  }
}
