import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css']
})
export class GraphComponent implements OnInit {
  energyArr: any = []
  thingsId: any
  graphDetails: any
  graphdata = []
  options: Object;
  weakdays: any = 'today'
  finalEnergyArr: any;
  finalIndoorTemp: any;
  finalOutdoorTemp: any;
  finalBaseArr: any;
  ashishArr = []
  constructor(private service: UserDataService) { }

  ngOnInit() {
    this.getGraphdata()
  }

  //get graph data of particular user
  getGraphdata() {
    let data = {
      "function_name": "graph_summary",
      "thingnames": JSON.parse(localStorage.getItem('allThingsName')),
      "weekdays": this.weakdays
    }
    this.service.postApi('/graph-summary', data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        var energyArr = [], indoorTemp = [], outdoorTemp = [], baseArr = [];
        this.graphDetails = res['body']['data']
        this.graphDetails.forEach((arr, arrIndex) => {

          this.ashishArr.push({
            'index': arrIndex,
            'length': arr.length
          })
          arr.forEach((obj, objIndex) => {
            var timeStamp = obj.time_stamp
            var timeStampDate = parseInt(timeStamp) * 1000
            var getDate = new Date(timeStampDate)
            if (this.weakdays == 'today') {
              baseArr.push(getDate.getHours() + 1 + ':00')
              if (energyArr[objIndex] == undefined && indoorTemp[objIndex] == undefined && outdoorTemp[objIndex] == undefined) {
                energyArr.push(parseFloat(obj.energyMeter))
                indoorTemp.push(parseFloat(obj.avgRoomTemp))
                outdoorTemp.push(parseFloat(obj.avgOutTemp))
              } else {
                energyArr[objIndex] = (parseFloat(energyArr[objIndex]) + parseFloat(obj.energyMeter))
                indoorTemp[objIndex] = (parseFloat(indoorTemp[objIndex]) + parseFloat(obj.avgRoomTemp))
                outdoorTemp[objIndex] = (parseFloat(outdoorTemp[objIndex]) + parseFloat(obj.avgOutTemp))
              }
            } else if (this.weakdays == 'week') {
              var newDate = getDate.getDate();
              var newMonth = getDate.getMonth();
              var newYear = getDate.getFullYear();
              if (baseArr[objIndex] == undefined) {
                var data = { 'objDates': newDate, 'objMonths': newMonth + 1, 'objYears': newYear, 'objEnergy': parseFloat(obj.energyMeter), 'objIndoor': parseFloat(obj.avgRoomTemp), 'objOutdoor': parseFloat(obj.avgOutTemp) }
                baseArr.push(data)
              } else {
                baseArr[objIndex].objEnergy = (parseFloat(baseArr[objIndex].objEnergy) + parseFloat(obj.energyMeter))
                baseArr[objIndex].objIndoor = (parseFloat(baseArr[objIndex].objIndoor) + parseFloat(obj.avgRoomTemp))
                baseArr[objIndex].objOutdoor = (parseFloat(baseArr[objIndex].objOutdoor) + parseFloat(obj.avgOutTemp))
              }
            } else if (this.weakdays == 'month') {
              var newDate = getDate.getDate();
              var newMonth = getDate.getMonth();
              var newYear = getDate.getFullYear();
              if (baseArr[objIndex] == undefined) {
                var data = { 'objDates': newDate, 'objMonths': newMonth + 1, 'objYears': newYear, 'objEnergy': parseFloat(obj.energyMeter), 'objIndoor': parseFloat(obj.avgRoomTemp), 'objOutdoor': parseFloat(obj.avgOutTemp) }
                baseArr.push(data)
              } else {
                baseArr[objIndex].objEnergy = (parseFloat(baseArr[objIndex].objEnergy) + parseFloat(obj.energyMeter))
                baseArr[objIndex].objIndoor = (parseFloat(baseArr[objIndex].objIndoor) + parseFloat(obj.avgRoomTemp))
                baseArr[objIndex].objOutdoor = (parseFloat(baseArr[objIndex].objOutdoor) + parseFloat(obj.avgOutTemp))
              }
            } else if (this.weakdays == 'year') {
              var newDate = getDate.getDate();
              var newMonth = getDate.getMonth();
              var newYear = getDate.getFullYear();
              if (baseArr[objIndex] == undefined) {
                var data = { 'objDates': newDate, 'objMonths': newMonth + 1, 'objYears': newYear, 'objEnergy': parseFloat(obj.energyMeter), 'objIndoor': parseFloat(obj.avgRoomTemp), 'objOutdoor': parseFloat(obj.avgOutTemp) }
                baseArr.push(data)
              } else {
                baseArr[objIndex].objEnergy = (parseFloat(baseArr[objIndex].objEnergy) + parseFloat(obj.energyMeter))
                baseArr[objIndex].objIndoor = (parseFloat(baseArr[objIndex].objIndoor) + parseFloat(obj.avgRoomTemp))
                baseArr[objIndex].objOutdoor = (parseFloat(baseArr[objIndex].objOutdoor) + parseFloat(obj.avgOutTemp))
              }
            }
          })
        })
        if (this.weakdays == 'today') {
          this.set24HourData(baseArr, energyArr, indoorTemp, outdoorTemp)
        } else if (this.weakdays == 'week') {
          this.setWeekData(baseArr)
        } else if (this.weakdays == 'month') {
          this.setMonthData(baseArr)
        } else if (this.weakdays == 'year') {
          this.setYearData(baseArr)
        }
      }
    }, (err) => {
    })
  }

  //set 24 hours data in graph
  set24HourData(baseArr, energyArr, indoorTemp, outdoorTemp) {
    energyArr.forEach((val, energyIndex) => {
      var energyData = energyArr[energyIndex] / this.graphDetails.length
      energyArr[energyIndex] = parseFloat(energyData.toFixed(1))
    })
    indoorTemp.forEach((val, indoorIndex) => {
      var indoorData = indoorTemp[indoorIndex] / this.graphDetails.length
      indoorTemp[indoorIndex] = parseFloat(indoorData.toFixed(1))
    })
    outdoorTemp.forEach((val, outIndex) => {
      var outdoorData = outdoorTemp[outIndex] / this.graphDetails.length
      outdoorTemp[outIndex] = parseFloat(outdoorData.toFixed(1))
    })
    this.finalEnergyArr = energyArr
    this.finalIndoorTemp = indoorTemp
    this.finalOutdoorTemp = outdoorTemp
    this.finalBaseArr = baseArr
    var newConsumedEnergy = [];
    newConsumedEnergy[0] = 0;
    for (let i = 1; i < energyArr.length; i++) {
      var value = energyArr[i] - energyArr[i - 1]
      newConsumedEnergy.push(parseFloat(value.toFixed(3)))
    }
    this.setGraph(newConsumedEnergy, indoorTemp, outdoorTemp, baseArr)
  }
  //set week data in graph
  setWeekData(baseArr) {
    var newEnergy, newIndoor, newOutdoor, newMonth, newDate, newYear, newArr = [], count = 1;
    baseArr.forEach((baseArrVal, baseArrIndex) => {
      if (baseArrIndex == 0) {
        newMonth = baseArrVal.objMonths;
        newDate = baseArrVal.objDates;
        newYear = baseArrVal.objYears;
        newEnergy = baseArrVal.objEnergy / this.graphDetails.length;
        newIndoor = baseArrVal.objIndoor / this.graphDetails.length;
        newOutdoor = baseArrVal.objOutdoor / this.graphDetails.length;
        var data = { 'objDates': newDate, 'objYears': newYear, 'objMonths': newMonth, 'objEnergy': parseFloat(newEnergy.toFixed(1)), 'objIndoor': parseFloat(newIndoor.toFixed(1)), 'objOutdoor': parseFloat(newOutdoor.toFixed(1)), 'count': count }
        newArr.push(data)
      } else {
        if (newMonth == baseArrVal.objMonths && newDate == baseArrVal.objDates) {
          ++count;
          newEnergy = newEnergy + (baseArrVal.objEnergy / this.graphDetails.length);
          newIndoor = newIndoor + (baseArrVal.objIndoor / this.graphDetails.length);
          newOutdoor = newOutdoor + (baseArrVal.objOutdoor / this.graphDetails.length);
          newArr.forEach((newArrVal, newArrIndex) => {
            if (newMonth == newArrVal.objMonths && newDate == newArrVal.objDates) {
              newArr[newArrIndex].objEnergy = parseFloat(newEnergy.toFixed(1))
              newArr[newArrIndex].objIndoor = parseFloat(newIndoor.toFixed(1))
              newArr[newArrIndex].objOutdoor = parseFloat(newOutdoor.toFixed(1))
              newArr[newArrIndex].count = count
            }
          })
        } else {
          count = 1
          newMonth = baseArrVal.objMonths;
          newDate = baseArrVal.objDates;
          newYear = baseArrVal.objYears;
          newEnergy = baseArrVal.objEnergy;
          newIndoor = baseArrVal.objIndoor;
          newOutdoor = baseArrVal.objOutdoor;
          var data = { 'objDates': newDate, 'objYears': newYear, 'objMonths': newMonth, 'objEnergy': parseFloat(newEnergy.toFixed(1)), 'objIndoor': parseFloat(newIndoor.toFixed(1)), 'objOutdoor': parseFloat(newOutdoor.toFixed(1)), 'count': count }
          newArr.push(data)
        }
      }
    })
    var finalEnergyArr = [], finalIndoorArr = [], finalOutdoorArr = [], finalWeekDaysArr = [];
    newArr.forEach(val => {
      var finalEnergyData = val.objEnergy / val.count
      var finalIndoorData = val.objIndoor / val.count
      var finalOutdoorData = val.objOutdoor / val.count
      var dateString = val.objYears + '-' + val.objMonths + '-' + val.objDates;
      var setDate = new Date(dateString)
      var finalDays = setDate.getDay();
      var dayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      finalWeekDaysArr.push(dayArr[finalDays])
      finalEnergyArr.push(parseFloat(finalEnergyData.toFixed(1)))
      finalIndoorArr.push(parseFloat(finalIndoorData.toFixed(1)))
      finalOutdoorArr.push(parseFloat(finalOutdoorData.toFixed(1)))
    })
    this.finalEnergyArr = finalEnergyArr
    this.finalIndoorTemp = finalIndoorArr
    this.finalOutdoorTemp = finalOutdoorArr
    this.finalBaseArr = finalWeekDaysArr
    var newConsumedEnergy = [];
    newConsumedEnergy[0] = 0;
    for (let i = 1; i < finalEnergyArr.length; i++) {
      var value = finalEnergyArr[i] - finalEnergyArr[i - 1]
      newConsumedEnergy.push(parseFloat(value.toFixed(3)))
    }
    this.setGraph(newConsumedEnergy, finalIndoorArr, finalOutdoorArr, finalWeekDaysArr)
  }

  //set month data in graph
  setMonthData(baseArr) {
    var newEnergy, newIndoor, newOutdoor, newMonth, newDate, newYear, newArr = [], count = 1;
    baseArr.forEach((baseArrVal, baseArrIndex) => {
      if (baseArrIndex == 0) {
        newMonth = baseArrVal.objMonths;
        newDate = baseArrVal.objDates;
        newYear = baseArrVal.objYears;
        newEnergy = baseArrVal.objEnergy / this.graphDetails.length;
        newIndoor = baseArrVal.objIndoor / this.graphDetails.length;
        newOutdoor = baseArrVal.objOutdoor / this.graphDetails.length;
        var data = { 'objDates': newDate, 'objYears': newYear, 'objMonths': newMonth, 'objEnergy': parseFloat(newEnergy.toFixed(1)), 'objIndoor': parseFloat(newIndoor.toFixed(1)), 'objOutdoor': parseFloat(newOutdoor.toFixed(1)), 'count': count }
        newArr.push(data)
      } else {
        if (newMonth == baseArrVal.objMonths && newDate == baseArrVal.objDates) {
          ++count;
          newEnergy = newEnergy + (baseArrVal.objEnergy / this.graphDetails.length);
          newIndoor = newIndoor + (baseArrVal.objIndoor / this.graphDetails.length);
          newOutdoor = newOutdoor + (baseArrVal.objOutdoor / this.graphDetails.length);
          newArr.forEach((newArrVal, newArrIndex) => {
            if (newMonth == newArrVal.objMonths && newDate == newArrVal.objDates) {
              newArr[newArrIndex].objEnergy = parseFloat(newEnergy.toFixed(1))
              newArr[newArrIndex].objIndoor = parseFloat(newIndoor.toFixed(1))
              newArr[newArrIndex].objOutdoor = parseFloat(newOutdoor.toFixed(1))
              newArr[newArrIndex].count = count
            }
          })
        } else {
          count = 1
          newMonth = baseArrVal.objMonths;
          newDate = baseArrVal.objDates;
          newYear = baseArrVal.objYears;
          newEnergy = baseArrVal.objEnergy;
          newIndoor = baseArrVal.objIndoor;
          newOutdoor = baseArrVal.objOutdoor;
          var data = { 'objDates': newDate, 'objYears': newYear, 'objMonths': newMonth, 'objEnergy': parseFloat(newEnergy.toFixed(1)), 'objIndoor': parseFloat(newIndoor.toFixed(1)), 'objOutdoor': parseFloat(newOutdoor.toFixed(1)), 'count': count }
          newArr.push(data)
        }
      }
    })
    var finalEnergyArr = [], finalIndoorArr = [], finalOutdoorArr = [], finalWeekDaysArr = [];
    newArr.forEach(val => {
      var finalEnergyData = val.objEnergy / val.count
      var finalIndoorData = val.objIndoor / val.count
      var finalOutdoorData = val.objOutdoor / val.count
      var dateString = val.objYears + '-' + val.objMonths + '-' + val.objDates;
      var setDate = new Date(dateString)
      var finalMonths = setDate.getMonth();
      var finalDates = setDate.getDate();
      var monthArr = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      finalWeekDaysArr.push(finalDates + ' ' + monthArr[finalMonths])
      finalEnergyArr.push(parseFloat(finalEnergyData.toFixed(1)))
      finalIndoorArr.push(parseFloat(finalIndoorData.toFixed(1)))
      finalOutdoorArr.push(parseFloat(finalOutdoorData.toFixed(1)))
    })
    this.finalEnergyArr = finalEnergyArr
    this.finalIndoorTemp = finalIndoorArr
    this.finalOutdoorTemp = finalOutdoorArr
    this.finalBaseArr = finalWeekDaysArr
    var newConsumedEnergy = [];
    newConsumedEnergy[0] = 0;
    for (let i = 1; i < finalEnergyArr.length; i++) {
      var value = finalEnergyArr[i] - finalEnergyArr[i - 1]
      newConsumedEnergy.push(parseFloat(value.toFixed(3)))
    }
    this.setGraph(newConsumedEnergy, finalIndoorArr, finalOutdoorArr, finalWeekDaysArr)
  }

  //set year data in graph
  setYearData(baseArr) {
    var newEnergy, newIndoor, newOutdoor, newMonth, newDate, newYear, newArr = [], count = 1;
    baseArr.forEach((baseArrVal, baseArrIndex) => {
      if (baseArrIndex == 0) {
        newMonth = baseArrVal.objMonths;
        newDate = baseArrVal.objDates;
        newYear = baseArrVal.objYears;
        newEnergy = baseArrVal.objEnergy / this.graphDetails.length;
        newIndoor = baseArrVal.objIndoor / this.graphDetails.length;
        newOutdoor = baseArrVal.objOutdoor / this.graphDetails.length;
        var data = { 'objDates': newDate, 'objYears': newYear, 'objMonths': newMonth, 'objEnergy': parseFloat(newEnergy.toFixed(1)), 'objIndoor': parseFloat(newIndoor.toFixed(1)), 'objOutdoor': parseFloat(newOutdoor.toFixed(1)), 'count': count }
        newArr.push(data)
      } else {
        if (newMonth == baseArrVal.objMonths && newYear == baseArrVal.objYears) {
          ++count;
          newEnergy = newEnergy + (baseArrVal.objEnergy / this.graphDetails.length);
          newIndoor = newIndoor + (baseArrVal.objIndoor / this.graphDetails.length);
          newOutdoor = newOutdoor + (baseArrVal.objOutdoor / this.graphDetails.length);
          newArr.forEach((newArrVal, newArrIndex) => {
            if (newMonth == newArrVal.objMonths && newYear == newArrVal.objYears) {
              newArr[newArrIndex].objEnergy = parseFloat(newEnergy.toFixed(1))
              newArr[newArrIndex].objIndoor = parseFloat(newIndoor.toFixed(1))
              newArr[newArrIndex].objOutdoor = parseFloat(newOutdoor.toFixed(1))
              newArr[newArrIndex].count = count
            }
          })
        } else {
          count = 1
          newMonth = baseArrVal.objMonths;
          newDate = baseArrVal.objDates;
          newYear = baseArrVal.objYears;
          newEnergy = baseArrVal.objEnergy;
          newIndoor = baseArrVal.objIndoor;
          newOutdoor = baseArrVal.objOutdoor;
          var data = { 'objDates': newDate, 'objYears': newYear, 'objMonths': newMonth, 'objEnergy': parseFloat(newEnergy.toFixed(1)), 'objIndoor': parseFloat(newIndoor.toFixed(1)), 'objOutdoor': parseFloat(newOutdoor.toFixed(1)), 'count': count }
          newArr.push(data)
        }
      }
    })
    var finalEnergyArr = [], finalIndoorArr = [], finalOutdoorArr = [], finalWeekDaysArr = [];

    newArr.forEach(val => {
      var finalEnergyData = val.objEnergy / val.count
      var finalIndoorData = val.objIndoor / val.count
      var finalOutdoorData = val.objOutdoor / val.count
      var dateString = val.objYears + '-' + val.objMonths + '-' + val.objDates;
      var setDate = new Date(dateString)
      var finalMonths = setDate.getMonth();
      var monthArr = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
      finalWeekDaysArr.push(monthArr[finalMonths])
      finalEnergyArr.push(parseFloat(finalEnergyData.toFixed(1)))
      finalIndoorArr.push(parseFloat(finalIndoorData.toFixed(1)))
      finalOutdoorArr.push(parseFloat(finalOutdoorData.toFixed(1)))
    })
    this.finalEnergyArr = finalEnergyArr
    this.finalIndoorTemp = finalIndoorArr
    this.finalOutdoorTemp = finalOutdoorArr
    this.finalBaseArr = finalWeekDaysArr
    var newConsumedEnergy = [];
    newConsumedEnergy[0] = 0;
    for (let i = 1; i < finalEnergyArr.length; i++) {
      var value = finalEnergyArr[i] - finalEnergyArr[i - 1]
      newConsumedEnergy.push(parseFloat(value.toFixed(3)))
    }
    this.setGraph(newConsumedEnergy, finalIndoorArr, finalOutdoorArr, finalWeekDaysArr)
  }

  //set graph data in graph
  setGraph(energyArr, indoorTemp, outdoorTemp, baseArr) {
    energyArr.forEach((element, i) => {
      if (element < 0) {
        energyArr[i] = Math.abs(element)
      }
    });
    this.energyArr = energyArr

    this.options = {
      chart: {
        zoomType: 'xy',
        width: 1100,
        height: 400
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      xAxis: [{
        categories: baseArr,
        crosshair: true
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          format: '{value} °C'
        },
        title: {
          text: 'Temperature'
        }
      }, { // Secondary yAxis
        title: {
          text: 'Energy'
        },
        labels: {
          format: '{value} KWh'
        },
        opposite: true
      }],
      tooltip: {
        shared: true
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        x: 120,
        verticalAlign: 'top',
        y: 100,
        floating: true
      },
      series: [{
        name: 'Energy Consumed',
        type: 'column',
        yAxis: 1,
        color: '#ffb276',
        data: energyArr,
        tooltip: {
          valueSuffix: ' KWh'
        }
      }, {
        name: 'Indoor Temperature',
        type: 'spline',
        color: 'blue',
        data: indoorTemp,
        tooltip: {
          valueSuffix: '°C'
        }
      }, {
        name: 'Outdoor Temperature',
        type: 'spline',
        color: 'red',
        data: outdoorTemp,
        tooltip: {
          valueSuffix: '°C'
        }
      }]
    };
  }

  //export csv using module
  exportToCsv() {
    var newFinalArr = [];
    this.graphDetails.forEach(arr => {
      var newArr = [];
      arr.forEach(obj => {
        var dateData = new Date(obj.time_stamp * 1000)
        newArr.push({ timeData: dateData.getHours() + 1 + ':00', dateData: dateData.getDate(), monthData: dateData.getMonth(), yearData: dateData.getFullYear(), dayData: dateData.getDay(), SerialNumber: obj.SN, energyValue: parseFloat(obj.energyMeter), inValue: parseFloat(obj.avgRoomTemp), outVal: parseFloat(obj.avgOutTemp) })
      })
      newArr.forEach(obj => {
        newFinalArr.push(obj)
      })
      newFinalArr.push({ dateData: '', timeData: '', monthData: '', yearData: '', dayData: '', SerialNumber: '', energyValue: '', inValue: '', outVal: '' })
    })
    var newFinalEnergy, newFinalIndoor, newFinalOutdoor, newSN, newMonth, newTime, newDate, newYear, newDay, newlastFinalArr = [], count = 1;
    if (this.weakdays == 'today') {
      newFinalArr.forEach(obj => {
        newlastFinalArr.push(obj)
      })
    } else if (this.weakdays == 'week') {
      newFinalArr.forEach((newFinalArrObj, newFinalArrInd) => {
        if (newFinalArrInd == 0) {
          newFinalEnergy = newFinalArrObj.energyValue;
          newFinalIndoor = newFinalArrObj.inValue;
          newFinalOutdoor = newFinalArrObj.outVal;
          newSN = newFinalArrObj.SerialNumber;
          newMonth = newFinalArrObj.monthData;
          newDate = newFinalArrObj.dateData;
          newYear = newFinalArrObj.yearData;
          newDay = newFinalArrObj.dayData;
          newTime = newFinalArrObj.timeData
          newlastFinalArr.push({ count: count, timeData: newTime, dayData: newDay, dateData: newDate, monthData: newMonth, yearData: newYear, SerialNumber: newSN, energyValue: newFinalEnergy, inValue: newFinalIndoor, outVal: newFinalOutdoor })
        } else {
          if (newFinalArrObj.SerialNumber == newSN && newFinalArrObj.timeData == newTime && newFinalArrObj.dateData == newDate && newFinalArrObj.monthData == newMonth) {
            ++count;
            newFinalEnergy = newFinalEnergy + newFinalArrObj.energyValue;
            newFinalIndoor = newFinalIndoor + newFinalArrObj.inValue;
            newFinalOutdoor = newFinalOutdoor + newFinalArrObj.outVal;
            newlastFinalArr.forEach(newlastFinalArrObj => {
              if (newlastFinalArrObj.SerialNumber == newSN && newFinalArrObj.timeData == newTime && newlastFinalArrObj.dateData == newDate && newlastFinalArrObj.monthData == newMonth) {
                newlastFinalArrObj.energyValue = newFinalEnergy;
                newlastFinalArrObj.inValue = newFinalIndoor;
                newlastFinalArrObj.outVal = newFinalOutdoor;
                newlastFinalArrObj.count = count;
              }
            })
          } else {
            count = 1;
            newFinalEnergy = newFinalArrObj.energyValue;
            newFinalIndoor = newFinalArrObj.inValue;
            newFinalOutdoor = newFinalArrObj.outVal;
            newSN = newFinalArrObj.SerialNumber;
            newMonth = newFinalArrObj.monthData;
            newDate = newFinalArrObj.dateData;
            newYear = newFinalArrObj.yearData;
            newDay = newFinalArrObj.dayData;
            newTime = newFinalArrObj.timeData

            newlastFinalArr.push({ count: count, timeData: newTime, dayData: newDay, dateData: newDate, monthData: newMonth, yearData: newYear, SerialNumber: newSN, energyValue: newFinalEnergy, inValue: newFinalIndoor, outVal: newFinalOutdoor })
          }
        }
      })
      newlastFinalArr.forEach(obj => {
        obj.energyValue = parseFloat((obj.energyValue / obj.count).toFixed(1))
        obj.inValue = parseFloat((obj.inValue / obj.count).toFixed(1))
        obj.outVal = parseFloat((obj.outVal / obj.count).toFixed(1))
      })
    } else if (this.weakdays == 'month') {
      newFinalArr.forEach((newFinalArrObj, newFinalArrInd) => {
        if (newFinalArrInd == 0) {
          newFinalEnergy = newFinalArrObj.energyValue;
          newFinalIndoor = newFinalArrObj.inValue;
          newFinalOutdoor = newFinalArrObj.outVal;
          newSN = newFinalArrObj.SerialNumber;
          newMonth = newFinalArrObj.monthData;
          newDate = newFinalArrObj.dateData;
          newYear = newFinalArrObj.yearData;
          newDay = newFinalArrObj.dayData;
          newTime = newFinalArrObj.timeData

          newlastFinalArr.push({ count: count, timeData: newTime, dayData: newDay, dateData: newDate, monthData: newMonth, yearData: newYear, SerialNumber: newSN, energyValue: newFinalEnergy, inValue: newFinalIndoor, outVal: newFinalOutdoor })
        } else {
          if (newFinalArrObj.SerialNumber == newSN && newFinalArrObj.timeData == newTime && newFinalArrObj.dayData == newDay && newFinalArrObj.dateData == newDate && newFinalArrObj.monthData == newMonth) {
            ++count;
            newFinalEnergy = newFinalEnergy + newFinalArrObj.energyValue;
            newFinalIndoor = newFinalIndoor + newFinalArrObj.inValue;
            newFinalOutdoor = newFinalOutdoor + newFinalArrObj.outVal;
            newlastFinalArr.forEach(newlastFinalArrObj => {
              if (newlastFinalArrObj.SerialNumber == newSN && newFinalArrObj.timeData == newTime && newFinalArrObj.dayData == newDay && newlastFinalArrObj.dateData == newDate && newlastFinalArrObj.monthData == newMonth) {
                newlastFinalArrObj.energyValue = newFinalEnergy;
                newlastFinalArrObj.inValue = newFinalIndoor;
                newlastFinalArrObj.outVal = newFinalOutdoor;
                newlastFinalArrObj.count = count;
              }
            })
          } else {
            count = 1;
            newFinalEnergy = newFinalArrObj.energyValue;
            newFinalIndoor = newFinalArrObj.inValue;
            newFinalOutdoor = newFinalArrObj.outVal;
            newSN = newFinalArrObj.SerialNumber;
            newMonth = newFinalArrObj.monthData;
            newDate = newFinalArrObj.dateData;
            newYear = newFinalArrObj.yearData;
            newDay = newFinalArrObj.dayData;
            newlastFinalArr.push({ count: count, timeData: newTime, dayData: newDay, dateData: newDate, monthData: newMonth, yearData: newYear, SerialNumber: newSN, energyValue: newFinalEnergy, inValue: newFinalIndoor, outVal: newFinalOutdoor })
          }
        }
      })
      newlastFinalArr.forEach(obj => {
        obj.energyValue = parseFloat((obj.energyValue / obj.count).toFixed(1))
        obj.inValue = parseFloat((obj.inValue / obj.count).toFixed(1))
        obj.outVal = parseFloat((obj.outVal / obj.count).toFixed(1))
      })
    } else if (this.weakdays == 'year') {
      newFinalArr.forEach((newFinalArrObj, newFinalArrInd) => {
        if (newFinalArrInd == 0) {
          newFinalEnergy = newFinalArrObj.energyValue;
          newFinalIndoor = newFinalArrObj.inValue;
          newFinalOutdoor = newFinalArrObj.outVal;
          newSN = newFinalArrObj.SerialNumber;
          newMonth = newFinalArrObj.monthData;
          newDate = newFinalArrObj.dateData;
          newYear = newFinalArrObj.yearData;
          newDay = newFinalArrObj.dayData;
          newTime = newFinalArrObj.timeData

          newlastFinalArr.push({ count: count, timeData: newTime, dayData: newDay, dateData: newDate, monthData: newMonth, yearData: newYear, SerialNumber: newSN, energyValue: newFinalEnergy, inValue: newFinalIndoor, outVal: newFinalOutdoor })
        } else {
          if (newFinalArrObj.SerialNumber == newSN && newFinalArrObj.timeData == newTime && newFinalArrObj.dayData == newDay && newFinalArrObj.yearData == newYear && newFinalArrObj.monthData == newMonth) {
            ++count;
            newFinalEnergy = newFinalEnergy + newFinalArrObj.energyValue;
            newFinalIndoor = newFinalIndoor + newFinalArrObj.inValue;
            newFinalOutdoor = newFinalOutdoor + newFinalArrObj.outVal;
            newlastFinalArr.forEach(newlastFinalArrObj => {
              if (newlastFinalArrObj.SerialNumber == newSN && newFinalArrObj.timeData == newTime && newFinalArrObj.dayData == newDay && newlastFinalArrObj.yearData == newYear && newlastFinalArrObj.monthData == newMonth) {
                newlastFinalArrObj.energyValue = newFinalEnergy;
                newlastFinalArrObj.inValue = newFinalIndoor;
                newlastFinalArrObj.outVal = newFinalOutdoor;
                newlastFinalArrObj.count = count;
              }
            })
          } else {
            count = 1;
            newFinalEnergy = newFinalArrObj.energyValue;
            newFinalIndoor = newFinalArrObj.inValue;
            newFinalOutdoor = newFinalArrObj.outVal;
            newSN = newFinalArrObj.SerialNumber;
            newMonth = newFinalArrObj.monthData;
            newDate = newFinalArrObj.dateData;
            newYear = newFinalArrObj.yearData;
            newDay = newFinalArrObj.dayData;
            newTime = newFinalArrObj.timeData

            newlastFinalArr.push({ count: count, timeData: newTime, dayData: newDay, dateData: newDate, monthData: newMonth, yearData: newYear, SerialNumber: newSN, energyValue: newFinalEnergy, inValue: newFinalIndoor, outVal: newFinalOutdoor })
          }
        }
      })
      newlastFinalArr.forEach(obj => {
        obj.energyValue = parseFloat((obj.energyValue / obj.count).toFixed(1))
        obj.inValue = parseFloat((obj.inValue / obj.count).toFixed(1))
        obj.outVal = parseFloat((obj.outVal / obj.count).toFixed(1))
      })
    }
    var data: any = [];
    var dayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    var monthArr = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    let newprinceArry = []

    for (let i = 0; i < newlastFinalArr.length - 1; i++) {

      if (i == 0) {
        newprinceArry.push(0)

      } else {
        if (newlastFinalArr[i].SerialNumber != '') {
          let summ = (newlastFinalArr[i].energyValue - newlastFinalArr[i - 1].energyValue).toString()
          newprinceArry.push(parseFloat(summ).toFixed(1))
        }
        else if (newlastFinalArr[i].SerialNumber == '') {
          newprinceArry.push('')
        }
      }
    }
    var zeroStatus = -1;
    for (let i = 0; i < newprinceArry.length; i++) {
      if (zeroStatus == i) {

        continue;
      } else {
        if (newlastFinalArr[i].SerialNumber != '') {
          newlastFinalArr[i].energyValue = newprinceArry[i]
        }
        if (newlastFinalArr[i].SerialNumber == '') {
          zeroStatus = i + 1

          newlastFinalArr[i + 1].energyValue = 0
        }
      }
    }
    for (let i = 0; i < newlastFinalArr.length; i++) {
      if (this.weakdays == 'today') {
        if (newlastFinalArr[i].energyValue === '') {
          data.push({ Time: '---', Day: '---', Date: '---', Serial_Number: '---', Energy: '---', Indoor_Temperature: '---', Outdoor_Temperature: '---' })


        } else {
          data.push({ Time: newlastFinalArr[i].timeData, Day: dayArr[newlastFinalArr[i].dayData], Date: newlastFinalArr[i].dateData + '/' + monthArr[newlastFinalArr[i].monthData] + '/' + newlastFinalArr[i].yearData, Serial_Number: newlastFinalArr[i].SerialNumber, Energy: newlastFinalArr[i].energyValue, Indoor_Temperature: newlastFinalArr[i].inValue, Outdoor_Temperature: newlastFinalArr[i].outVal })

        }
      } else if (this.weakdays == 'week') {
        if (newlastFinalArr[i].energyValue === '') {
          data.push({ Day: '---', Date: '---', Time: '---', Serial_Number: '---', Energy: '---', Indoor_Temperature: '---', Outdoor_Temperature: '---' })
        } else {
          data.push({ Day: dayArr[newlastFinalArr[i].dayData], Date: newlastFinalArr[i].dateData + '/' + monthArr[newlastFinalArr[i].monthData] + '/' + newlastFinalArr[i].yearData, Time: newlastFinalArr[i].timeData, Serial_Number: newlastFinalArr[i].SerialNumber, Energy: newlastFinalArr[i].energyValue, Indoor_Temperature: newlastFinalArr[i].inValue, Outdoor_Temperature: newlastFinalArr[i].outVal })
        }
      } else if (this.weakdays == 'month') {
        if (newlastFinalArr[i].energyValue === '') {
          data.push({ Date: '---', Day: '---', Time: '---', Serial_Number: '---', Energy: '---', Indoor_Temperature: '---', Outdoor_Temperature: '---' })
        } else {
          data.push({ Date: newlastFinalArr[i].dateData + '/' + (newlastFinalArr[i].monthData + 1) + '/' + newlastFinalArr[i].yearData, Day: dayArr[newlastFinalArr[i].dayData], Time: newlastFinalArr[i].timeData, Serial_Number: newlastFinalArr[i].SerialNumber, Energy: newlastFinalArr[i].energyValue, Indoor_Temperature: newlastFinalArr[i].inValue, Outdoor_Temperature: newlastFinalArr[i].outVal })
        }
      } else if (this.weakdays == 'year') {
        if (newlastFinalArr[i].energyValue === '') {
          data.push({ Day: '---', daydata: '---', Date: '---', Time: '---', Serial_Number: '---', Energy: '---', Indoor_Temperature: '---', Outdoor_Temperature: '---' })
        } else {
          data.push({ Month: monthArr[newlastFinalArr[i].monthData], daydata: dayArr[newlastFinalArr[i].dayData], Date: newlastFinalArr[i].dateData + '/' + monthArr[newlastFinalArr[i].monthData] + '/' + newlastFinalArr[i].yearData, Time: newlastFinalArr[i].timeData, Serial_Number: newlastFinalArr[i].SerialNumber, Energy: newlastFinalArr[i].energyValue, Indoor_Temperature: newlastFinalArr[i].inValue, Outdoor_Temperature: newlastFinalArr[i].outVal })
        }
      }
    }



    var deviceSnData = JSON.parse(localStorage.getItem('allThingsName'));
    var fileName;
    var fileNameDate = new Date()
    if (deviceSnData.length > 1) {
      fileName = 'Group Report' + '_' + fileNameDate.getDate() + '_' + (fileNameDate.getMonth() + 1) + '_' + fileNameDate.getFullYear()
    } else {
      fileName = deviceSnData[0].thingname + '_' + fileNameDate.getDate() + '_' + (fileNameDate.getMonth() + 1) + '_' + fileNameDate.getFullYear()
    }
    if (this.weakdays == 'today') {
      var options = {
        headers: ["Time", "Day", "Date", "Serial_Number", "Energy(KWh)", "Indoor_Temperature(°C)", "Outdoor_Temperature(°C)"],
      };
      new Angular5Csv(data, fileName + '_' + '24Hours', options);
    } else if (this.weakdays == 'week') {
      var options = {
        headers: ["Day", "Date", "Time", "Serial_Number", "Energy(KWh)", "Indoor_Temperature(°C)", "Outdoor_Temperature(°C)"],
      };
      new Angular5Csv(data, fileName + '_' + '7Days', options);
    } else if (this.weakdays == 'month') {
      var options = {
        headers: ["Date", "Day", "Time", "Serial_Number", "Energy(KWh)", "Indoor_Temperature(°C)", "Outdoor_Temperature(°C)"],
      };
      new Angular5Csv(data, fileName + '_' + '30Days', options);
    } else if (this.weakdays == 'year') {
      var options = {
        headers: ["Month", "Day", "Date", "Time", "Serial_Number", "Energy(KWh)", "Indoor_Temperature(°C)", "Outdoor_Temperature(°C)"],
      };
      new Angular5Csv(data, fileName + '_' + '12Months', options);
    }
  }
}
