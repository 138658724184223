import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { UserDataService } from '../../provider/user-data.service';
import { Router } from '../../../../node_modules/@angular/router';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePassForm: any
  isTrue: boolean = false
  constructor(private service: UserDataService, private router: Router, private spinner: NgxSpinnerService) {
    this.changePassForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.maxLength(16), Validators.minLength(8), Validators.required]),
      newPassword: new FormControl('', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.maxLength(16), Validators.minLength(8), Validators.required]),
      cPassword: new FormControl('', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.maxLength(16), Validators.minLength(8), Validators.required]),
    }, passwordMatchValidator);
    function passwordMatchValidator(matchPassword: FormGroup) {
      return matchPassword.get('newPassword').value === matchPassword.get('cPassword').value ? null : { mismatch: true };
    }

  }
  ngOnInit() {
  }

  // function of change password api 
  changPasswordSubmit() {
    this.spinner.show();
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var self = this
    var oldPass = this.changePassForm.value.oldPassword, newPass = this.changePassForm.value.newPassword;
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          self.spinner.hide();
          self.service.error('Something Went Wrong')
        } else {
          cognitoUser.changePassword(oldPass, newPass, function (err, result) {
            self.spinner.hide();
            if (result == "SUCCESS") {
              self.service.success('Password changed successfully')
              self.router.navigate(['/my-profile'])
            } else {
              self.service.error('Something Went Wrong')
            }
          });

        }
      });
    }
  }

}
