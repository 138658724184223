import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { IMyDpOptions, IMyDefaultMonth } from 'mydatepicker';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';


@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.css']
})
export class EditUserProfileComponent implements OnInit {
  now: Date = new Date();
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: { year: this.now.getFullYear() - 16, month: this.now.getMonth() + 1, day: this.now.getDate() + 1 },
    showTodayBtn: false,
    monthSelector: true,
    yearSelector: true,
  };
  defaultMonth: IMyDefaultMonth = {
    defMonth: (this.now.getFullYear() - 16) + '/' + (this.now.getMonth() + 1)
  }
  newEmail: any;
  typeOfUser: any
  parentCode: any
  roleData: any = [];
  roleName: any = [];
  date: any
  readWrite: any
  read: any
  checkbox: any
  check: any
  emailcheck: any
  appcheck: any
  is_read = false
  is_app_alert = false
  is_email_alert = false
  roleList: any
  url: any;
  id: any;
  userData: any;
  profileObj: {};
  isEmailAlert: any;
  isAppAlert: any;
  email: string;
  editUserProfileForm: any;
  userType: any;
  getMonth: number;
  getYear: number;
  getDay: number;
  dateVal: any;
  newDate: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'role_name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: "All",
    allowSearchFilter: true
  };
  countryListJson: any = [];
  companyNameUser: any;
  companyNameErrorMessage: any = ''


  constructor(private service: UserDataService, private route: ActivatedRoute, private router: Router) {
    this.roleName = {
      "role_name": new Array<string>()
    }
    var countryList = [];
    this.service.countryListJson.forEach(element => {
      countryList.push(element.name)
    });
    this.countryListJson = countryList;
    this.editUserProfileForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i), Validators.minLength(2), Validators.required]),
      lastName: new FormControl('', [Validators.pattern(/^[a-zA-Z ]*$/i), Validators.minLength(2), Validators.required]),
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.minLength(10), Validators.required]),
      companyName: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]*$/), Validators.minLength(3), Validators.required]),
      jobTitle: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(3), Validators.required]),
      parentCode: new FormControl('', [Validators.pattern(/^[A-Za-z0-9\-]*$/), Validators.minLength(4), Validators.required]),
      country: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.pattern(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/), Validators.minLength(7), Validators.required]),
    })
  }

  ngOnInit() {
    this.parentCode = localStorage.getItem('userId')
    this.typeOfUser = localStorage.getItem('userType')
    this.url = this.route.params.subscribe(params => {
      this.id = params
      this.newEmail = this.id.email;
    })
    this.getUserDetails()
    this.getRoleName()
    this.getUserData()
  }

  // +++++++++++++++++++++++++++ get role name  api manage ++++++++++++++++++++++++++++


  getRoleName() {
    let data = {
      "function_name": "get_roles_names",
    }
    this.service.postApi("/get-roles-names", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.roleName = res['body']['body']
      }
    }, (err) => {
    })
  }
  // +++++++++++++++++++++++++++ get user details  api manage ++++++++++++++++++++++++++++

  getUserDetails() {
    let data =
    {
      "function_name": "admin_get_user",
      "username": this.id.email,
    }
    this.service.postApi("/admin-get-user", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.userData = res['body']['data']['UserAttributes']
        this.roleData = res['body']['data']['roles']
        this.patchFormValue();
      }
    }, (err) => {
    })
  }

  // +++++++++++++++++++++++++++ patch value of form data ++++++++++++++++++++++++++++

  patchFormValue() {
    let obj = {};
    for (let i in this.userData) {
      obj[this.userData[i]['Name']] = this.userData[i]['Value'];
    }
    this.editUserProfileForm.patchValue({
      firstName: obj['custom:first_name'],
      lastName: obj['custom:last_name'],
      email: obj['email'],
      companyName: obj['custom:company_name'],
      jobTitle: obj['custom:job_title'],
      parentCode: obj['custom:parent_code'],
      phoneNumber: obj['custom:phone_number'],
      country: obj['custom:country_name'] ? obj['custom:country_name'] : '',
      city: obj['custom:city_name'],
    })

    this.isEmailAlert = obj['custom:is_email_alert']
    this.isAppAlert = obj['custom:is_app_alert']
    this.is_app_alert = obj['custom:is_app_alert'] == '1' ? true : false
    this.is_email_alert = obj['custom:is_email_alert'] == '1' ? true : false
    this.is_read = obj['custom:rw'] == '2' ? true : false
    if (obj['custom:user_type'] === '1') {
      this.userType = '1'
    } else if (obj['custom:user_type'] == '2') {
      this.userType = '2'
    } else if (obj['custom:user_type'] == '3') {
      this.userType = '3'
    } else if (obj['custom:user_type'] == '4') {
      this.userType = '4'
    }
    this.date = obj['custom:date_of_birth']
    this.getMonth = new Date(this.date).getMonth() + 1
    this.getYear = new Date(this.date).getFullYear()
    this.getDay = new Date(this.date).getDate()
    this.dateVal = { "date": { "year": this.getYear, "month": this.getMonth, "day": this.getDay } }
    this.newDate = obj['custom:date_of_birth']
  }

  save() {
    if (this.is_app_alert == true) {
      this.checkbox = '1'
    } else {
      this.checkbox = '0'
    }
    if (this.is_email_alert == true) {
      this.check = '1'
    } else {
      this.check = '0'
    }
    if (this.is_read == true) {
      this.readWrite = '2'
    } else {
      this.readWrite = '1'
    }
    var formData = this.editUserProfileForm.value;
    let data = {
      "function_name": "admin_update_user_attributes",
      "company_name": formData.companyName,
      "admin_code": formData.parentCode,
      "job_title": formData.jobTitle,
      "is_app_alert": this.checkbox,
      "is_email_alert": this.check,
      "first_name": formData.firstName,
      "last_name": formData.lastName,
      "date_of_birth": this.newDate,
      "phone_number": formData.phoneNumber,
      "username": this.id.email,
      "userType": this.userType,
      "readWrite": this.readWrite,
      "new_multi_role": this.roleData,
      "userRole": '0',
      "parentCode": formData.parentCode,
      "country_name": formData.country,
      "city_name": formData.city
    }
    this.service.postApi("/admin-update-user-attributes", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.service.success("User Updated successfully")
        this.router.navigate(['/user-management'])
      } else if (res['body']['statusCode'] == 203) {
        this.service.error("You can't create admin to this user, as this user is not of your company!")
      } else {
        this.service.error('Something Went Wrong')
      }
    }, (err) => {
    })
  }

  // +++++++++++++++++++++++++++ change date format ++++++++++++++++++++++++++++

  changeDateValue() {
    this.newDate = '';
    this.newDate = this.dateVal.formatted;
  }

  // +++++++++++++++++++++++++++ manage data of get user ++++++++++++++++++++++++++++

  getUserData() {
    var self = this;
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem("userEmail"),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    if (cognitoUser != null) {

      cognitoUser.getSession(function (err, session) {
        cognitoUser.getUserData((err, userData) => {
          userData.UserAttributes.forEach(obj => {
            if (obj.Name == 'custom:company_name') {
              self.companyNameUser = obj.Value
            }
          })
          if (err) {
          }
        });
      });
    }
  }

  checkCompany() {
    if (this.editUserProfileForm.value.companyName != this.companyNameUser && this.userType == '3') {
      this.companyNameErrorMessage = "You can't assign admin to this user, as this user is not of your company!"
    } else {
      this.companyNameErrorMessage = ""
    }
  }
}
