import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { conditionallyCreateMapObjectLiteral } from '../../../../node_modules/@angular/compiler/src/render3/view/util';
declare var $: any;
@Component({
  selector: 'app-define-role',
  templateUrl: './define-role.component.html',
  styleUrls: ['./define-role.component.css']
})
export class DefineRoleComponent implements OnInit {
  permission: any;
  checkApp: any;
  checkApplAlt = 1;
  checkMailAlt = 1;
  checkMail: any;
  checkBoxVal: any
  isAppAlert: any;
  is_app_alert: any;
  checkVal = 1 
  filData = {} 
  creatRoleObj = {}
  dataArr = []
  newUserArr = []
  newPersonArr = []
  newCountryArr = []
  newCityArr = []
  newSnArr = []
  newUnitArr = []
  newRoomArr = []
  newSiteArr = []
  userReq = {}
  roleDetails: any
  roleObj = {} 
  roleData = {} 
  profileObj: any
  roleForm: FormGroup;
  selectCountry: any;
  disabled: boolean = false;

  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 'All',
    allowSearchFilter: true
  };
  dropdownList: any;
  user: any;
  tokenId: any;
  totalData = []
  totalDataArray = []
  dashboardDetals = []
  showData: boolean;
  shadowList: string;
  disableFilterOnchange: boolean;
  constructor(private service: UserDataService, private router: Router) {
    this.roleForm = new FormGroup({
    })
    this.dropdownList = [
      'New Delhi', 'Mumbai'
    ];
    this.disableFilterOnchange = false;
  }
  ngOnInit() {
    this.showData = true;
    this.roleData = {
      "user_name": new Array<string>(),
      "person_type": new Array<string>(),
      "country": new Array<string>(),
      "city": new Array<string>(),
      "site": new Array<string>(),
      "room": new Array<string>(),
      "unit_name": new Array<string>(),
      "SN": new Array<string>()
    }
    this.creatRoleObj = {
      "selectCountry": new Array<string>(),
      "selectPerson": new Array<string>(),
      "selectCity": new Array<string>(),
      "selectSite": new Array<string>(),
      "selectRoom": new Array<string>(),
      "selectUnit": new Array<string>(),
      "selectSN": new Array<string>(),
      "userName": new Array<string>()
    }
    if (localStorage.userType == '3') {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }
  // +++++++++++++++++++++++++++  check value of checkbox  ++++++++++++++++++++++++++++

  checked() {
    this.checkBoxVal = $("#checkbox1").is(":checked") ? "true" : "false";
    this.checkVal = this.checkBoxVal == "true" ? 2 : 1
  }
  // +++++++++++++++++++++++++++  check mail value of checkbox  ++++++++++++++++++++++++++++

  checkedemailAlt() {
    this.checkMail = $("#checkbox2").is(":checked") ? "true" : "false";
    this.checkMailAlt = this.checkMail == "true" ? 2 : 1
  }
  // +++++++++++++++++++++++++++  check App value of checkbox  ++++++++++++++++++++++++++++


  checkedappAlt() {
    this.checkApp = $("#checkbox3").is(":checked") ? "true" : "false";
    this.checkApplAlt = this.checkApp == "true" ? 2 : 1
  }
  // +++++++++++++++++++++++++++  check user access value of checkbox  ++++++++++++++++++++++++++++


  userAccess(val) {
    this.permission = val
  }


  // +++++++++++++++++++++++++++ Create role api  ++++++++++++++++++++++++++++

  createRole(rolename, person_type, userName, Country, City, Site, Room, Unit, SN) {
    var userObj = {}
    for (var i in userName) {
      userObj[userName[i]] = "S"
    }

    var personObj = {}
    for (var i in person_type) {
      personObj[person_type[i]] = "S"
    }

    var CountryObj = {}
    for (var i in Country) {
      CountryObj[Country[i]] = "S"
    }
    var CityObj = {}
    for (var i in City) {
      CityObj[City[i]] = "S"
    }
    var SiteObj = {}
    for (var i in Site) {
      SiteObj[Site[i]] = "S"
    }
    var RoomObj = {}
    for (var i in Room) {
      RoomObj[Room[i]] = "S"
    }
    var UnitObj = {}
    for (var i in Unit) {
      UnitObj[Unit[i]] = "S"
    }
    var SNObj = {}
    for (var i in SN) {
      SNObj[SN[i]] = "S"
    }
    let data =
    {
      "function_name": "create_role",
      "roleDetails": {
        "role_name": rolename,
        "customer_name": userObj,
        "user_name": personObj,
        "country": CountryObj,
        "city": CityObj,
        "site": SiteObj,
        "room": RoomObj,
        "unit_name": UnitObj,
        "SN": SNObj,
        "rw": this.checkVal,
        "is_app_alert": this.checkApplAlt,
        "is_email_alert": this.checkMailAlt,
        "is_permission": this.permission
      }
    }
    this.service.postApi("/create-role", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.service.success('Role created successfully')
        this.router.navigate(['/role-management'])
      }
    }, (err) => {
      this.service.error('Something Went Wrong')
    })
  }

  // +++++++++++++++++++++++++++  Change role on particular selection key  ++++++++++++++++++++++++++++


  countryChange(checkVal) {
    if (checkVal == 2) {
      this.creatRoleObj['selectCity'] = []
      this.creatRoleObj['selectSite'] = []
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }

    var countryArr = []
    for (let i in this.creatRoleObj['selectCountry']) {
      countryArr.push({ "Value": this.creatRoleObj['selectCountry'][i] })
    }
    this.newCountryArr = countryArr
  }

  cityChange(checkVal) {
    if (checkVal == 3) {
      this.creatRoleObj['selectSite'] = []
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }

    var cityArr = []
    for (let i in this.creatRoleObj['selectCity']) {
      cityArr.push({ "Value": this.creatRoleObj['selectCity'][i] })

    }
    this.newCityArr = cityArr
  }



  async userChange(checkVal) {
    if (this.disableFilterOnchange)
      return;
    this.showData = false;
    if (checkVal == 1) {
      this.roleData["country"] = [];
      this.roleData["person_type"] = []
      this.roleData["city"] = [];
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];
      this.creatRoleObj['selectCountry'] = [];
      this.creatRoleObj['selectPerson'] = [];
      this.creatRoleObj['selectCity'] = []
      this.creatRoleObj['selectSite'] = []
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }
    if (checkVal == 2) {

      this.roleData["country"] = [];
      this.roleData["city"] = [];
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];
      this.creatRoleObj['selectCountry'] = [];
      this.creatRoleObj['selectCity'] = []
      this.creatRoleObj['selectSite'] = []
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }

    if (checkVal == 3) {

      this.roleData["city"] = [];
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];
      this.creatRoleObj['selectCity'] = []
      this.creatRoleObj['selectSite'] = []
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }
    if (checkVal == 4) {

      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];
      this.creatRoleObj['selectSite'] = []
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }
    if (checkVal == 5) {
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];
      this.creatRoleObj['selectRoom'] = []
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }
    if (checkVal == 6) {
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];
      this.creatRoleObj['selectUnit'] = []
      this.creatRoleObj['selectSN'] = []
    }
    if (checkVal == 7) {
      this.roleData["SN"] = [];
      this.creatRoleObj['selectSN'] = []
    }

    var userArr = []
    for (let i in this.creatRoleObj['userName']) {
      userArr.push({ "value": this.creatRoleObj['userName'][i] })
    }
    this.newUserArr = userArr

    var personArr = []
    for (let i in this.creatRoleObj['selectPerson']) {
      personArr.push({ "value": this.creatRoleObj['selectPerson'][i] })
    }
    this.newPersonArr = personArr

    var countryArr = []
    for (let i in this.creatRoleObj['selectCountry']) {
      countryArr.push({ "value": this.creatRoleObj['selectCountry'][i] })
    }
    this.newCountryArr = countryArr

    var cityArr = []
    for (let i in this.creatRoleObj['selectCity']) {
      cityArr.push({ "value": this.creatRoleObj['selectCity'][i] })

    }
    this.newCityArr = cityArr

    var siteArr = []
    for (let i in this.creatRoleObj['selectSite']) {
      siteArr.push({ "value": this.creatRoleObj['selectSite'][i] })
    }
    this.newSiteArr = siteArr

    var roomArr = []
    for (let i in this.creatRoleObj['selectRoom']) {
      roomArr.push({ "value": this.creatRoleObj['selectRoom'][i] })
    }
    this.newRoomArr = roomArr

    var snArr = []
    for (let i in this.creatRoleObj['selectSN']) {
      snArr.push({ "value": this.creatRoleObj['selectSN'][i] })
    }
    this.newSnArr = snArr


    this.filData = {
      "user": [],
      "function_name": "filter_device_details",
      "customer": this.newUserArr,
      "person": this.newPersonArr,
      "country": this.newCountryArr,
      "city": this.newCityArr,
      "site": this.newSiteArr,
      "room": this.newRoomArr,
      "unit": this.newUnitArr,
      "SN": this.newSnArr
    }
    this.showData = true;
    this.disableFilterOnchange = true;
    this.showData = false;
    let resp = await this.callAPI(this.filData);
  }
  callAPI(data): Promise<any> {
    return new Promise((resolv, reject) => {
      this.getFilterdata(data);
      resolv("");
    });
  }

  // +++++++++++++++++++++++++++  filter data api  ++++++++++++++++++++++++++++



  getFilterdata(data) {
    this.service.postApi("/filter-device-details", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.dataArr = res['body']['data']
        var dashboardData = []
        for (var i in this.dataArr) {

          if (!this.roleData["user_name"].includes(this.dataArr[i]["customer"])) {
            this.roleData["user_name"].push(this.dataArr[i]["customer"])
          }


          if (!this.roleData["person_type"].includes(this.dataArr[i]["person"])) {
            this.roleData["person_type"].push(this.dataArr[i]["person"])
          }
          if (!this.roleData["country"].includes(this.dataArr[i]["country"])) {
            this.roleData["country"].push(this.dataArr[i]["country"])
          }
          if (!this.roleData["city"].includes(this.dataArr[i]["city"])) {
            this.roleData["city"].push(this.dataArr[i]["city"])
          }
          if (!this.roleData["unit_name"].includes(this.dataArr[i]["unit_type"])) {
            this.roleData["unit_name"].push(this.dataArr[i]["unit_type"])
          }
          if (!this.roleData["room"].includes(this.dataArr[i]["room"])) {
            this.roleData["room"].push(this.dataArr[i]["room"])
          }
          if (!this.roleData["site"].includes(this.dataArr[i]["site"])) {
            this.roleData["site"].push(this.dataArr[i]["site"])
          }
          if (!this.roleData["SN"].includes(this.dataArr[i]["SN"])) {
            this.roleData["SN"].push(this.dataArr[i]["SN"])
          }
        }
        if (localStorage.userType == '3') {
          this.creatRoleObj['userName'] = [this.dataArr[0].customer]
        }
        else {
          this.disabled = false;
        }
        this.showData = true;
        setTimeout(() => {
          this.disableFilterOnchange = false;
        }, 1000);
      }
      else if (res['body']['statusCode'] == 400) {
      } else {
      }
    }, (err) => {
    })
  }

  cancel() {
    this.router.navigate(['role-management'])

  }

}
