import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

declare var $: any;
var copyOfAllData = [];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  objAlarm: any = { 'major': false, 'minor': false };
  dashboardDetails: any = [];
  dashboardList: any = [];
  filteredDashboardList: any = [];
  currentPage: number = 1;
  paginationLimit: number = 100;
  filterListData: any = {};
  dropdownSettings = {
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    idField: 'item_id',
    textField: 'item_text'
  };
  dropdownFilterData = {
    customer: [],
    person: [],
    country: [],
    city: [],
    site: [],
    room: [],
    SN: [],
  };
  searchBarData: string;
  type: any;
  inviteForm: FormGroup;
  user_id: string;
  toggle: boolean = true;
  thingsArr: any = [];
  allCheckBox: any = false;
  firstTime = true;

  parameterList: any = [];
  allParameterData: any = [];
  groupUpdateParameter: any;
  groupUpdateParameterValue: any = '';
  invalidParameterValueMessage: any = '';
  invalidParameterNameMessage: any = '';
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;

  adminCodeForInvite: any;
  site: any;
  sitevalue: string;
  customer: string;
  country: string;
  city: string;
  room: string;
  unit: string;
  SN: string;



  constructor(private service: UserDataService, private router: Router, private route: ActivatedRoute) {

    this.type = localStorage.getItem("userType")
    this.filterListData = {
      "customerFilterList": new Array<string>(),
      "userNameFilterList": new Array<string>(),
      "countryFilterList": new Array<string>(),
      "cityFilterList": new Array<string>(),
      "siteFilterList": new Array<string>(),
      "roomFilterList": new Array<string>(),
      "snFilterList": new Array<string>()
    }
    this.inviteForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required]),
      subject: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(5), Validators.required]),
      message: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(5), Validators.required])

    })
  }

  ngOnInit() {
    this.site = localStorage.getItem('site');
    this.customer = localStorage.getItem('customer');
    this.country = localStorage.getItem('country');
    this.city = localStorage.getItem('city');
    this.room = localStorage.getItem('room');
    this.unit = localStorage.getItem('unit');
    this.SN = localStorage.getItem('SN');
    this.DeviceDetails()
    this.getUserData()
    this.getUserAttributes()
    if (localStorage.getItem('allThingsName')) {
      localStorage.removeItem('allThingsName');
    }

  }

  //Get all data of particular user
  getData() {
    return new Promise(resolve => {
      let data = {
        "function_name": 'thing_name_new'
      }
      this.service.postApi("/thing-name-new", data).subscribe((res) => {
        if (res['body']['statusCode'] == 200) {
          this.dashboardList = res['body']['data'];
          copyOfAllData = res['body']['data'];
          this.dashboardList.forEach((obj) => {
            obj.selected = false;
          });
          copyOfAllData.forEach((obj) => {
            obj.selected = false;
          });
          this.firstTime = true;
          this.filteredDashboardList = this.dashboardList;
          this.dashboardDetails = this.dashboardList;  
          var customerFilterList = [], userNameFilterList = [], countryFilterList = [], cityFilterList = [], siteFilterList = [], roomFilterList = [], snFilterList = [];
          this.dashboardList.forEach(obj => {            
            customerFilterList.push(obj.customer.trim())
            userNameFilterList.push(obj.person.trim())
            countryFilterList.push(obj.country.trim())
            cityFilterList.push(obj.city.trim())
            siteFilterList.push(obj.site.trim())
            roomFilterList.push(obj.room.trim())
            snFilterList.push(obj.SN.trim())
          })
          this.filterListData['customerFilterList'] = this.removeDuplicates(customerFilterList)
          this.filterListData['userNameFilterList'] = this.removeDuplicates(userNameFilterList)
          this.filterListData['countryFilterList'] = this.removeDuplicates(countryFilterList)
          this.filterListData['cityFilterList'] = this.removeDuplicates(cityFilterList)
          this.filterListData['siteFilterList'] = this.removeDuplicates(siteFilterList)
          this.filterListData['roomFilterList'] = this.removeDuplicates(roomFilterList)
          this.filterListData['snFilterList'] = this.removeDuplicates(snFilterList)
          if (this.site != null) {
            this.applyAllFilter();
          } else if (this.customer != null) {
            this.applyAllFilter();
          } else if (this.country != null) {
            this.applyAllFilter();
          } else if (this.city != null) {
            this.applyAllFilter();
          } else if (this.room != null) {
            this.applyAllFilter();
          } else if (this.unit != null) {
            this.applyAllFilter();
          } else if (this.SN != null) {
            this.applyAllFilter();
          }
        } else {
          this.service.error("Api Response Error")
        }
        resolve(true);
      }, (err) => {
        resolve(true)
      })
      });
  }


  //manage filter functionality
  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  //view particular group details
  viewGroupUpdate() {
    this.thingsArr = []
    this.dashboardDetails.forEach((obj) => {
      if (obj.selected == true) {
        this.thingsArr.push({ "thingname": obj.SN })
      }
    });
    if (this.thingsArr.length) {
      localStorage.setItem('allThingsName', JSON.stringify(this.thingsArr))
      $('#group_update').modal('show');
      this.getAllParameters();
    } else {
      this.service.error('Select device for group update!')
    }
  }

  //get filter data of aa]ll paramaters and remove duplicates
  getAllParameters() {
    let data = {
      "function_name": "get_thing_shadow_key",
      "thingnames": JSON.parse(localStorage.getItem('allThingsName'))
    }
    this.service.postApi("/get-thing-shadow-key", data).subscribe((res) => {
      var prameters = [];
      this.allParameterData = res['body']['data'];
      this.allParameterData.forEach(obj => {
        for (let key in obj) {
          prameters.push(key)
        }
      })
      this.parameterList = this.removeDuplicates(prameters)
      this.options = this.parameterList;
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    }, err => {
    })
  }

  //Change value of group paramater
  groupUpdateCheckValue() {
    if (this.groupUpdateParameter == '' || this.groupUpdateParameter == undefined) {
      this.invalidParameterNameMessage = '*Parameter is required!'
    } else {
      this.invalidParameterNameMessage = ''
    }
    var count = 0;
    var val = +this.groupUpdateParameterValue;
    if (this.groupUpdateParameter == 'minSetpoint') {
      this.allParameterData.forEach(obj => {
        if (isNaN(val) || val > obj['normalSetpoint'] || isNaN(val) || val < 15) {
          count++;
        }
      })
      if (count > 0) {
        this.invalidParameterValueMessage = '*Please enter valid minSetpoint'
      } else {
        this.invalidParameterValueMessage = ''
      }
    } else if (this.groupUpdateParameter == 'normalSetpoint') {
      this.allParameterData.forEach(obj => {
        if (isNaN(val) || val > obj['setbackSetpoint'] || val < obj['minSetpoint']) {
          count++;
        }
      })
      if (count > 0) {
        this.invalidParameterValueMessage = '*Please enter valid normalSetpoint'
      } else {
        this.invalidParameterValueMessage = ''
      }
    } else if (this.groupUpdateParameter == 'setbackSetpoint') {
      this.allParameterData.forEach(obj => {
        if (isNaN(val) || val > obj['maxSetpoint'] || val < obj['normalSetpoint']) {
          count++;
        }
      })
      if (count > 0) {
        this.invalidParameterValueMessage = '*Please enter valid setbackSetpoint'
      } else {
        this.invalidParameterValueMessage = ''
      }
    } else if (this.groupUpdateParameter == 'maxSetpoint') {
      this.allParameterData.forEach(obj => {
        if (isNaN(val) || val > 40 || val < obj['setbackSetpoint']) {
          count++;
        }
      })
      if (count > 0) {
        this.invalidParameterValueMessage = '*Please enter valid maxSetpoint'
      } else {
        this.invalidParameterValueMessage = ''
      }
    } else if (this.groupUpdateParameter == 'minCompSpeed') {
      this.allParameterData.forEach(obj => {
        if (isNaN(val) || val > obj['maxCompSpeed'] || val < 32) {
          count++;
        }
      })
      if (count > 0) {
        this.invalidParameterValueMessage = '*Please enter valid minCompSpeed'
      } else {
        this.invalidParameterValueMessage = ''
      }
    } else if (this.groupUpdateParameter == 'maxCompSpeed') {
      this.allParameterData.forEach(obj => {
        if (isNaN(val) || val > 100 || val < obj['minCompSpeed']) {
          count++;
        }
      })
      if (count > 0) {
        this.invalidParameterValueMessage = '*Please enter valid maxCompSpeed'
      } else {
        this.invalidParameterValueMessage = ''
      }
    }
  }

  //manage group update data
  groupUpdateApi() {
    if (this.invalidParameterValueMessage == '' && this.groupUpdateParameterValue != '') {
      var serialNumbers = JSON.parse(localStorage.getItem('allThingsName'));
      var finalSN = []
      serialNumbers.forEach(obj => {
        finalSN.push(obj.thingname)
      })
      let data = {
        "function_name": "group_update_thing_shadow",
        "thingnames": finalSN,
        "update_device": {
          [this.groupUpdateParameter]: this.groupUpdateParameterValue
        }
      }
      this.service.postApi("/group-update-thing-shadow", data).subscribe((res) => {
        $('#alert_prompt').modal('hide');
        this.service.success(res['body']['responseMessage'])
        this.groupUpdateParameterValue = '';
        this.groupUpdateParameter = '';
        this.invalidParameterValueMessage = '';
        this.invalidParameterNameMessage = '';
      }, (err) => {
        this.service.error('Something Went Wrong')
      })
    }
  }

  //call modal to integrate functionality
  additionalPrompt() {
    $('#alert_prompt').modal('show');
    $('#group_update').modal('hide');
  }

  //close group update modal
  closeGroupUpdateModal() {
    this.groupUpdateParameterValue = '';
    this.groupUpdateParameter = '';
    this.invalidParameterValueMessage = '';
    this.invalidParameterNameMessage = '';
    $('#group_update').modal('hide');
    $('#alert_prompt').modal('hide');

  }


  //manage serach filter
  searchFilter(searchBarVal) {
    this.dashboardDetails = []
    this.dashboardDetails = this.dashboardList.filter(obj =>
      obj.customer.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.person.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.country.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.city.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.site.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.room.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.SN.includes(searchBarVal))
  }

  //get device details  data
  DeviceDetails() {
    let data = {
      "function_name": 'thing_name_new'
    }
    this.service.postApi("/thing-name-new", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.dashboardList = res['body']['data'];
        copyOfAllData = res['body']['data'];
        this.dashboardList.forEach((obj) => {
          obj.selected = false;
        });
        copyOfAllData.forEach((obj) => {
          obj.selected = false;
        });
        this.firstTime = true;
        this.filteredDashboardList = this.dashboardList;
        this.dashboardDetails = this.dashboardList;
        var customerFilterList = [], userNameFilterList = [], countryFilterList = [], cityFilterList = [], siteFilterList = [], roomFilterList = [], snFilterList = [];
        this.dashboardList.forEach(obj => {
          
          customerFilterList.push(obj.customer.trim())
          userNameFilterList.push(obj.person.trim())
          countryFilterList.push(obj.country.trim())
          cityFilterList.push(obj.city.trim())
          siteFilterList.push(obj.site.trim())
          roomFilterList.push(obj.room.trim())
          snFilterList.push(obj.SN.trim())
        })
        this.filterListData['customerFilterList'] = this.removeDuplicates(customerFilterList)
        this.filterListData['userNameFilterList'] = this.removeDuplicates(userNameFilterList)
        this.filterListData['countryFilterList'] = this.removeDuplicates(countryFilterList)
        this.filterListData['cityFilterList'] = this.removeDuplicates(cityFilterList)
        this.filterListData['siteFilterList'] = this.removeDuplicates(siteFilterList)
        this.filterListData['roomFilterList'] = this.removeDuplicates(roomFilterList)
        this.filterListData['snFilterList'] = this.removeDuplicates(snFilterList)
        if (this.site != null) {
          this.applyAllFilter();
        } else if (this.customer != null) {
          this.applyAllFilter();
        } else if (this.country != null) {
          this.applyAllFilter();
        } else if (this.city != null) {
          this.applyAllFilter();
        } else if (this.room != null) {
          this.applyAllFilter();
        } else if (this.unit != null) {
          this.applyAllFilter();
        } else if (this.SN != null) {
          this.applyAllFilter();
        }
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })
  }

 //Apply filter functionality
  applyAllFilter() {
    var filterArray = ['customer', 'person', 'country', 'city', 'site', 'room', 'SN']
    this.filteredDashboardList = copyOfAllData
    var count = 0;
    if (this.site != null) {
      var site = this.site;
      this.dropdownFilterData['site'].push(site);
    } else if (this.customer != null) {
      var customer = this.customer
      this.dropdownFilterData['customer'].push(customer);
    } else if (this.country != null) {
      var country = this.country
      this.dropdownFilterData['country'].push(country);
    } else if (this.city != null) {
      var city = this.city
      this.dropdownFilterData['city'].push(city);
    } else if (this.room != null) {
      var room = this.room
      this.dropdownFilterData['room'].push(room);
    } else if (this.unit != null) {
      var unit = this.unit
      this.dropdownFilterData['unit'].push(unit);
    } else if (this.SN != null) {
      var SN = this.SN
      this.dropdownFilterData['SN'].push(SN);
    }
    filterArray.forEach(eachKey => {
      var eachKeyValueArr = [], checkArr = [];
      if (this.dropdownFilterData[eachKey].length > 0) {
        this.dropdownFilterData[eachKey].forEach(eachKeyValue => {
          eachKeyValueArr = this.filteredDashboardList.filter(obj => obj[eachKey].includes(eachKeyValue))
          eachKeyValueArr.forEach(filteredObj => {
            checkArr.push(filteredObj)
          })
        })
        this.searchBarData = ''
        this.filteredDashboardList = []
        this.filteredDashboardList = checkArr
        this.dashboardDetails = []
        
        filterArray = []
        this.dashboardDetails = this.filteredDashboardList
        this.dropdownFilterData.site = [];
      } else {
        count++;
        if (count == 7) {
          this.dashboardDetails = copyOfAllData
        }
        if(this.objAlarm.major == true)
          this.dashboardDetails = this.dashboardDetails.filter(x => x.major_alarm == 1)
        if(this.objAlarm.minor == true)
          this.dashboardDetails = this.dashboardDetails.filter(x => x.minor_alarm == 1)
      }
    })
  }

  //clear all filter functionality
  clearFilter() {
    this.dropdownFilterData = {
      customer: [],
      person: [],
      country: [],
      city: [],
      site: [],
      room: [],
      SN: []
    };
    this.searchBarData = ''
    this.dashboardDetails = copyOfAllData;
    this.objAlarm.major = this.objAlarm.minor = false;
  }


  //remove all duplicates 
  removeDuplicates(arr) {
    let unique_array = []
    for (let i = 0; i < arr.length; i++) {
      if (unique_array.indexOf(arr[i]) == -1 && arr[i] != 'Undefined' && arr[i] != 'undefined') {
        unique_array.push(arr[i])
      }
    }
    return unique_array
  }


  //manage pagination functionality
  pagination(pageNo) {
    this.currentPage = pageNo
  }

  //manage checkbox functionllity is true or false
  allCheck() {
    if (this.allCheckBox) {
      this.dashboardDetails.forEach((obj) => {
        obj.selected = true;
      });
    } else {
      this.thingsArr = []
      this.dashboardDetails.forEach((obj) => {
        obj.selected = false;
      });
    }
  }

  singleCheck() {
    this.allCheckBox = false;
  }


  //view all summary
  viewSummary() {
    this.thingsArr = []
    this.dashboardDetails.forEach((obj) => {
     
      if (obj.selected == true) {
        this.thingsArr.push({ "thingname": obj.SN })
        // console.log('thingsArr==>>',this.thingsArr)
      }
    });
    if (this.thingsArr.length) {
      localStorage.setItem('allThingsName', JSON.stringify(this.thingsArr))
      this.router.navigate(['/summary'])
    } else {
      this.service.error('Select device to view summary!')
    }
  }

  //send data to device dashboard using storage
  sendToDeviceDashboard(id) {
    this.thingsArr = []
    this.thingsArr.push({ "thingname": id });
    localStorage.setItem('allThingsName', JSON.stringify(this.thingsArr));
  }

  //navigation on graph screen
  viewGraph() {
    this.thingsArr = []
    this.dashboardDetails.forEach((obj) => {
      if (obj.selected == true) {
        this.thingsArr.push({ "thingname": obj.SN })
      }
    });
    if (this.thingsArr.length) {
      localStorage.setItem('allThingsName', JSON.stringify(this.thingsArr))
      this.router.navigate(['/graph'])
    } else {
      this.service.error('Select device to view graph!')
    }
  }

  /*************************************** TABLE SORTING ******************************************** */
  compareASC(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property == 'time_stamp') {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      } else {
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
      }
      return result * sortOrder;
    }
  }

  compareDSC(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property == 'time_stamp') {
        var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      } else {
        var result = (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() < b[property].toLowerCase()) ? 1 : 0;
      }
      return result * sortOrder;
    }
  }

  sortList(sortingKey) {
    if (this.toggle == true) {
      this.dashboardDetails.sort(this.compareASC(sortingKey));
      this.currentPage = 1;
      this.toggle = false
    } else {
      this.dashboardDetails.sort(this.compareDSC(sortingKey));
      this.currentPage = 1;
      this.toggle = true
    }
  }
  /*************************************** TABLE SORTING END ******************************************** */

  Goinvite() {
    this.inviteForm.reset();
    this.user_id = localStorage.getItem('userId')
  }

  //integration of invitation
  inviteSubmit() {
    var totalInviteMessage = this.inviteForm.value.message + " Please follow the Link for signup https://mean.mobiloitte.com:1420/sign-up?admin_code=" + this.adminCodeForInvite
    let data = {
      "function_name": "send_ses_email_user",
      "recipient": this.inviteForm.value.email,
      "message": totalInviteMessage
    }
    this.service.postApi("/send-ses-email-user", data).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.success('Invitation sent successfully')
      } else {
        this.service.error(res['statusText'])
      }
    }, (err) => {
    })
  }


  //get user data  
  getUserData() {
    var self = this;
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem("userEmail"),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    if (cognitoUser != null) {

      cognitoUser.getSession(function (err, session) {
        cognitoUser.getUserData((err, userData) => {
          userData.UserAttributes.forEach(obj => {
            if (obj.Name == 'custom:parent_code') {
              self.adminCodeForInvite = obj.Value
            }
          })
          if (err) {
          }
        });
      });
    }
  }

  getUserAttributes() {
    var data = {
      UserPoolId: 'us-east-1_FBju9grOp',
      ClientId: '73itd0a6o426vup20afs3aqdna'
    };
    var userPool = new CognitoUserPool(data);
    let cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        cognitoUser.getUserAttributes(function (err, result) {
          if (err) {
          }
          for (let i = 0; i < result.length; i++) {
          }
        });
      });
    }
  }


  // view dismiss
  ngOnDestroy() {
    localStorage.removeItem('site')
    localStorage.removeItem('customer');
    localStorage.removeItem('country');
    localStorage.removeItem('city');
    localStorage.removeItem('room');
    localStorage.removeItem('unit');
    localStorage.removeItem('SN');
  }


}