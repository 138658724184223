import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms'
import { UserDataService } from '../../provider/user-data.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  isDisable: boolean = false
  settingForm: FormGroup
  thingStorage: any
  thingsName: any
  thing: any
  thingsDetails: any
  thingsId: any;
  userType: string;
  roleName: any;
  roleList = []
  maxRole: any;
  userRwPermission: any
  finalRwPermission: number;
  myValue: any;
  airConForm: FormGroup;
  thermostatForm: FormGroup;
  FallbackWifiForm: FormGroup;
  contactDetailForm: FormGroup;
  identityConfigForm: FormGroup;
  invalidForm: boolean = false;
  tabName: any = 'Aircon';
  timeZoneArr: any = [];
  excludeDaysList = [
    { item_id: 'SUN', item_text: 'Sunday' },
    { item_id: 'MON', item_text: 'Monday' },
    { item_id: 'TUE', item_text: 'Tuesday' },
    { item_id: 'WED', item_text: 'Wednesday' },
    { item_id: 'THU', item_text: 'Thrusday' },
    { item_id: 'FRI', item_text: 'Friday' },
    { item_id: 'SAT', item_text: 'Saturday' }];

  dropdownSettings = {
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    idField: 'item_id',
    textField: 'item_text'
  };

  occupyErrorMessage: any;
  setbackDelayErrorMessage: any;
  setbackActiveErrorMessage: any;
  awayErrorMessage: any;
  checkBoxErrorDiv: any;
  invalidAirconForm: boolean = false;
  invalidThermostatForm: boolean = false;
  excludeDaysValue: any = []
  alarmCounter: number = 0;
  thingsArr: any = [];
  countryListJson: any = [];


  constructor(private router: Router, private route: ActivatedRoute, private service: UserDataService) {
    this.countryListJson = this.service.countryListJson;
    this.airConForm = new FormGroup({
      minSetpoint: new FormControl('', [Validators.required]),
      maxSetpoint: new FormControl('', [Validators.required]),
      normalSetpoint: new FormControl('', [Validators.required]),
      setbackSetpoint: new FormControl('', [Validators.required]),
      maxCompSpeed: new FormControl('', [Validators.required]),
      powerUpMode: new FormControl('', [Validators.required]),
      minCompSpeed: new FormControl('', [Validators.required])
    })

    this.contactDetailForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required]),
      phoneNumber: new FormControl('', [Validators.pattern("[1-9]{1}[0-9]*"), Validators.minLength(8), Validators.required]),
      serviceEmail: new FormControl('', [Validators.required])
    })

    this.FallbackWifiForm = new FormGroup({
      wifiSSID: new FormControl('', [Validators.required]),
      wifiPass: new FormControl('', [Validators.required])
    })

    this.identityConfigForm = new FormGroup({
      teamNum: new FormControl('', [Validators.required, Validators.pattern('[0-9]')]),
      customer: new FormControl('', [Validators.required]),
      user: new FormControl('', [Validators.required, Validators.pattern('[A-Z]*')]),
      country: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z][A-Z a-z]*')]),
      site: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9\\s]+$")]),
      room: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9-\\s]+$")]),
      unit: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')])
    })

    this.thermostatForm = new FormGroup({
      setbackTime: new FormControl('', [Validators.required]),
      timezone: new FormControl('', [Validators.required]),
      allowOnTime: new FormControl('', [Validators.required]),
      onSetbackTime: new FormControl('', [Validators.required]),
      excludeDays: new FormControl('', [Validators.required]),
      offTime: new FormControl('', [Validators.required]),
      occupancyPeriodHr: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      occupancyPeriodMin: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      awayPeriodHr: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      awayPeriodMin: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      setbackDelayHr: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      setbackDelayMin: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      setbackActiveHr: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      setbackActiveMin: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      occupacyPeriodCheck: new FormControl(false),
      setbackDelayPeriodCheck: new FormControl(false),
      awayActivePeriodCheck: new FormControl(false),
      setbackActivePeriodCheck: new FormControl(false),
      allowOnTimeCheck: new FormControl(false),
      onSetbackTimeCheck: new FormControl(false),
      setbackTimeCheck: new FormControl(false),
      offTimeCheck: new FormControl(false)
    })
  }

  ngOnInit() {
    var serialNumber = JSON.parse(localStorage.getItem('allThingsName'))
    this.thingStorage = serialNumber[0].thingname;
    this.userType = localStorage.getItem('userType')
    let data = {
      "function_name": "get_role",
    }
    this.service.postApi("/get-role", data).subscribe((res) => {
      this.roleName = res['body']
      for (let x in this.roleName) {
        this.roleList.push(this.roleName[x]['rw'])
      }
      this.maxRole = Math.max.apply(null, this.roleList)
      this.userRwPermission = localStorage.getItem('userAccess')
      this.finalRwPermission = Math.min(this.maxRole, this.userRwPermission)
    }, (err) => {
    })
    this.getDeviceDetails();
    this.getAlarmCounter();
    this.timeZoneArr = this.service.timeZoneArr;
  }


  //get device details and manage data
  getDeviceDetails() {
    let data = {
      "function_name": "get_thing_shadow_new",
      "thingnames": [
        { "thingname": localStorage.getItem('thingStorage') }
      ]
    }
    this.service.postApi("/get-thing-shadow-new", data).subscribe((res) => {
      if (res['body']['data']) {
        this.thingsDetails = res['body']['data'][0]
        var excludeDaysNew = [];
        if (this.thingsDetails['excludeDays'] != '') {
          this.excludeDaysList.forEach((obj) => {
            if (this.thingsDetails['excludeDays'] == obj.item_id) {
              excludeDaysNew.push(obj)
            }
          })
          this.excludeDaysValue = excludeDaysNew
        }

        this.patchFormValues();
        this.airconInputChanged();
      } else {
        this.service.error("There is no data ")
      }
    }, (err) => {
    })
  }

  //patch value in form
  patchFormValues() {
    this.airConForm.patchValue({
      maxSetpoint: parseFloat(this.thingsDetails['maxSetpoint']).toFixed(1),
      minSetpoint: parseFloat(this.thingsDetails['minSetpoint']).toFixed(1),
      normalSetpoint: parseFloat(this.thingsDetails['normalSetpoint']).toFixed(1),
      setbackSetpoint: parseFloat(this.thingsDetails['setbackSetpoint']).toFixed(1),
      minCompSpeed: (parseFloat(this.thingsDetails['minCompSpeed']) * 1.25).toFixed(1),
      maxCompSpeed: (parseFloat(this.thingsDetails['maxCompSpeed']) * 1.25).toFixed(1),
      powerUpMode: this.thingsDetails['powerUpMode']
    })

    this.thermostatForm.patchValue({
      occupancyPeriod: this.thingsDetails['occupancyPeriod'],
      awayPeriod: this.thingsDetails['awayPeriod'],
      setbackDelay: this.thingsDetails['setbackDelay'],
      setbackTime: this.thingsDetails['setbackDelay'],
      timezone: this.thingsDetails['timezone'],
      allowOnTime: this.thingsDetails['allowOnTime'],
      onSetbackTime: this.thingsDetails['onSetbackTime'],
      offTime: this.thingsDetails['offTime']
    })

    this.FallbackWifiForm.patchValue({
      wifiSSID: this.thingsDetails['wifiSSID'],
      wifiPass: this.thingsDetails['wifiPass']
    })

    this.contactDetailForm.patchValue({
      email: this.thingsDetails['userEmail'],
      serviceEmail: this.thingsDetails['serviceEmail'],
      phoneNumber: this.thingsDetails['userPhone']
    })

    this.identityConfigForm.patchValue({
      teamNum: this.thingsDetails['teamNum'],
      customer: this.thingsDetails['customer'],
      user: this.thingsDetails['user'].toUpperCase(),
      country: this.thingsDetails['country'],
      city: this.thingsDetails['city'],
      site: this.thingsDetails['site'],
      room: this.thingsDetails['room'],
      unit: this.thingsDetails['unit']
    })
  }

  //save aircon details 
  saveAircon() {
    if (this.invalidAirconForm == false) {
      var minCompSpeed, maxCompSpeed, setbackSetpoint, normalSetpoint;
      minCompSpeed = parseFloat(this.airConForm.value.minCompSpeed).toFixed(1);
      minCompSpeed = minCompSpeed * 0.8;
      maxCompSpeed = parseFloat(this.airConForm.value.maxCompSpeed).toFixed(1);
      maxCompSpeed = maxCompSpeed * 0.8;
      setbackSetpoint = parseFloat(this.airConForm.value.setbackSetpoint).toFixed(1);
      normalSetpoint = parseFloat(this.airConForm.value.normalSetpoint).toFixed(1);

      let data = {
        "update_device": {
          "maxSetpoint": (parseInt(this.airConForm.value.maxSetpoint)).toString(),
          "minSetpoint": (parseInt(this.airConForm.value.minSetpoint)).toString(),
          "normalSetpoint": normalSetpoint.toString(),
          "setbackSetpoint": setbackSetpoint.toString(),
          "powerUpMode": this.airConForm.value.powerUpMode,
          "minCompSpeed": parseInt(minCompSpeed).toString(),
          "maxCompSpeed": parseInt(maxCompSpeed).toString(),
        },
        "thingnames": this.thingStorage,
        "function_name": "update_thing_shadow_new"
      }
      this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
        var message = res['body']['responseMessage']
        if (message == "You don't have write permission..") {
          this.service.error(message)
        } else {
          if (message) {
            this.service.error(message)
          } else {
            this.service.success('Aircon setting updated successfully');
          }
        }
      }, (err) => {
        this.service.error(err['body']['responseMessage'])
      })
    }
  }

  //save Thermostat details 
  saveThermostat() {
    if (this.invalidThermostatForm == false) {
      var formData = this.thermostatForm.value
      var newArr = []
      this.excludeDaysValue.forEach(obj => {
        newArr.push(obj.item_id)
      })
      let data = {
        "update_device": {
          "setbackTime": '',
          "excludeDays": newArr.toString(),
          "timezone": formData.timezone,
          "occupancyPeriod": "",
          "setbackDelay": "",
          "allowOnTime": '',
          "offTime": '',
          "onSetbackTime": '',
          "awayPeriod": "",
          "setbackPeriod": ""
        },
        "thingnames": this.thingStorage,
        "function_name": "update_thing_shadow_new"
      }

      if (formData.allowOnTimeCheck == true) {
        data.update_device.allowOnTime = formData.allowOnTime
      }
      if (formData.awayActivePeriodCheck == true) {
        let hr = parseInt(formData.awayPeriodHr)
        let min = parseInt(formData.awayPeriodMin)
        let newAwayValue = (hr * 60) + min
        data.update_device.awayPeriod = newAwayValue.toString()
      }
      if (formData.occupacyPeriodCheck == true) {
        let hr = parseInt(formData.occupancyPeriodHr)
        let min = parseInt(formData.occupancyPeriodMin)
        let newAwayValue = (hr * 60) + min
        data.update_device.occupancyPeriod = newAwayValue.toString()
      }
      if (formData.setbackActivePeriodCheck == true) {
        let hr = parseInt(formData.setbackActiveHr)
        let min = parseInt(formData.setbackActiveMin)
        let newAwayValue = (hr * 60) + min
        data.update_device.setbackPeriod = newAwayValue.toString();
      }
      if (formData.setbackDelayPeriodCheck == true) {
        let hr = parseInt(formData.setbackDelayHr)
        let min = parseInt(formData.setbackDelayMin)
        let newAwayValue = (hr * 60) + min
        data.update_device.setbackDelay = newAwayValue.toString()
      }
      if (formData.onSetbackTimeCheck == true) {
        data.update_device.onSetbackTime = formData.onSetbackTime
      }
      if (formData.setbackTimeCheck == true) {
        data.update_device.setbackTime = formData.setbackTime
      }
      if (formData.offTimeCheck == true) {
        data.update_device.offTime = formData.offTime
      }

      this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
        var message = res['body']['responseMessage']
        if (message == "You don't have write permission..") {
          this.service.error(message)
        } else {
          if (message) {
            this.service.error(message)
          } else {
            this.service.success('Thermostat configuration setting updated successfully')
            this.router.navigate(['/dashboard'])
          }
        }
      }, (err) => {
        this.service.error(err['body']['responseMessage'])
      })
    }
  }

  saveFallback() {
    let data = {
      "update_device": {
        wifiSSID: this.FallbackWifiForm.value.wifiSSID,
        wifiPass: this.FallbackWifiForm.value.wifiPass
      },
      "thingnames": this.thingStorage,
      "function_name": "update_thing_shadow_new"
    }
    this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
      var message = res['body']['responseMessage']
      if (message == "You don't have write permission..") {
        this.service.error(message)
      } else {
        if (message) {
          this.service.error(message)
        } else {
          this.service.success('Fallback wifi configuration setting updated successfully')
          this.router.navigate(['/dashboard'])
        }
      }
    }, (err) => {
      this.service.error(err['body']['responseMessage'])
    })

  }

  saveContact() {
    let data = {
      "update_device": {
        userEmail: this.contactDetailForm.value.email,
        serviceEmail: this.contactDetailForm.value.serviceEmail,
        userPhone: this.contactDetailForm.value.phoneNumber
      },
      "thingnames": this.thingStorage,
      "function_name": "update_thing_shadow_new"
    }
    this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
      var message = res['body']['responseMessage']
      if (message == "You don't have write permission..") {
        this.service.error(message)
      } else {
        if (message) {
          this.service.error(message)
        } else {
          this.service.success('Contact details setting updated successfully')
          this.router.navigate(['/dashboard'])
        }
      }
    }, (err) => {
      this.service.error(err['body']['responseMessage'])
    })

  }

  saveIdentification() {
    let data = {
      "update_device": {
        teamNum: this.identityConfigForm.value.teamNum,
        customer: this.identityConfigForm.value.customer,
        user: this.identityConfigForm.value.user,
        country: this.identityConfigForm.value.country,
        city: this.identityConfigForm.value.city,
        site: this.identityConfigForm.value.site,
        room: this.identityConfigForm.value.room,
        unit: this.identityConfigForm.value.unit,
      },
      "thingnames": this.thingStorage,
      "function_name": "update_thing_shadow_new"
    }
    this.service.postApi("/update-thing-shadow-new", data).subscribe((res) => {
      var message = res['body']['responseMessage']
      if (message == "You don't have write permission..") {
        this.service.error(message)
      } else {
        if (message) {
          this.service.error(message)
        } else {
          this.service.success('Identification configuration setting updated successfully')
          this.router.navigate(['/dashboard'])
        }
      }
    }, (err) => {
      this.service.error(err['body']['responseMessage'])
    })

  }

  airconInputChanged() {
    var minSetpoint, maxSetpoint, normalSetpoint, setbackSetpoint, minCompSpeed, maxCompSpeed;
    minSetpoint = parseFloat(this.airConForm.value.minSetpoint);
    maxSetpoint = parseFloat(this.airConForm.value.maxSetpoint);
    normalSetpoint = parseFloat(this.airConForm.value.normalSetpoint);
    setbackSetpoint = parseFloat(this.airConForm.value.setbackSetpoint);
    minCompSpeed = parseFloat(this.airConForm.value.minCompSpeed);
    maxCompSpeed = parseFloat(this.airConForm.value.maxCompSpeed);

    if (minSetpoint < 15.0) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Minimum setpoint should be at least 15 degree celsius.'
    }

    else if (minSetpoint > 40.0) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Minimum setpoint should be less than 40 degree celsius.'
    }

    else if (maxSetpoint > 40.0 || maxSetpoint < minSetpoint) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Maximum setpoint should be at most 40 degree celsius and more than minimum setpoint.'
    }

    else if (normalSetpoint < minSetpoint || normalSetpoint > maxSetpoint) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Normal setpoint should be in between min-max setpoint degree celsius.'
    }

    else if (normalSetpoint > setbackSetpoint) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Normal set point should be less than set back point.'
    }

    else if (setbackSetpoint < minSetpoint || setbackSetpoint > maxSetpoint) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Setback setpoint should be in between normal and max setpoint degree celsius.'
    }

    else if (minCompSpeed < 0 || minCompSpeed > 100.0) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Minimum compressor speed should be in between 0 to 100 %.'
    }

    else if (maxCompSpeed < minCompSpeed || maxCompSpeed > 100.0) {
      this.invalidAirconForm = true;
      document.getElementById('errorMessageDiv').innerHTML = '*Maximum compressor speed should be more than minimum compressor speed and less than 100 %.'
    }

    else {
      this.invalidAirconForm = false;
      document.getElementById('errorMessageDiv').innerHTML = ''
    }
  }

  tabchanged(tab) {
    this.tabName = tab;
    this.invalidForm = false;
    document.getElementById('errorMessageDiv').innerHTML = ''
    this.patchFormValues();
  }

  checkBoxFun(x, y, z) {
    this.thermostatForm.patchValue({
      [y]: '00', [z]: '01'
    })
    this.thermostatInputChanged(x);
  }

  thermostatInputChanged(thermostatField) {
    var formValue = this.thermostatForm.value;
    if (thermostatField == 'occupacyPeriodCheck') {
      if (formValue.occupancyPeriodHr > 8 || formValue.occupancyPeriodMin > 59 || (formValue.occupancyPeriodHr == 8 && formValue.occupancyPeriodMin > 0)) {
        this.invalidThermostatForm = true
        this.occupyErrorMessage = '*Maximum value for occupy active period is 8 hrs.'
      } else {
        this.invalidThermostatForm = false
        this.occupyErrorMessage = ''
      }
    } else if (thermostatField == 'awayActivePeriodCheck') {
      if (formValue.awayPeriodHr > 8 || formValue.awayPeriodMin > 59 || (formValue.awayPeriodHr == 8 && formValue.awayPeriodMin > 0)) {
        this.invalidThermostatForm = true
        this.awayErrorMessage = '*Maximum value for away active period is 8 hrs.'
      } else {
        this.invalidThermostatForm = false
        this.awayErrorMessage = ''
      }
    } else if (thermostatField == 'setbackDelayPeriodCheck') {
      if (formValue.setbackDelayHr > 8 || formValue.setbackDelayMin > 59 || (formValue.setbackDelayHr == 8 && formValue.setbackDelayMin > 0)) {
        this.invalidThermostatForm = true
        this.setbackDelayErrorMessage = '*Maximum value for setback delay period is 8 hrs.'
      } else {
        this.invalidThermostatForm = false
        this.setbackDelayErrorMessage = ''
      }
    } else if (thermostatField == 'setbackActivePeriodCheck') {
      if (formValue.setbackActiveHr > 8 || formValue.setbackActiveMin > 59 || (formValue.setbackActiveHr == 8 && formValue.setbackActiveMin > 0)) {
        this.invalidThermostatForm = true
        this.setbackActiveErrorMessage = '*Maximum value for setback active period is 8 hrs.'
      } else {
        this.invalidThermostatForm = false
        this.setbackActiveErrorMessage = ''
      }
    } else {
      this.invalidThermostatForm = false
    }
  }

  getAlarmCounter() {
    let data = {
      "function_name": "device_alarm_status_new",
      "thingname": this.thingStorage
    }
    this.service.postApi("/device-alarm-status-new", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.thingsDetails = res['body']['data']
        var alarmCounter = [];
        this.thingsDetails.forEach(obj => {
          if (obj[2] == '1') {
            if (obj[1][1] == 'Alarm Major') {
              alarmCounter.push(obj[1][0])
            } else if (obj[1][1] == 'Alarm Minor') {
              alarmCounter.push(obj[1][0])
            }
          }
          this.alarmCounter = alarmCounter.length;
        })
      } else {
        this.service.error("There is no data ")
      }
    }, (err) => {
    })
  }
}
