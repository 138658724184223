import { Component, OnInit } from '@angular/core';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { FormGroup, FormControl } from '../../../../node_modules/@angular/forms';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  page: any
  otpValue: string;
  otpVerify(): any {
    throw new Error("Method not implemented.");
  }
  uuId: any
  otpData: any = {}
  otpVerificationData: any
  otpform: FormGroup
  showEmailDiv: any = false;
  constructor(private router: Router, private service: UserDataService, private route: ActivatedRoute, private spinner: NgxSpinnerService) {
    this.route.params.subscribe((params) => {
      this.page = params.id
    })
    this.otpform = new FormGroup({
      emailOtp1: new FormControl(''),
      emailOtp2: new FormControl(''),
      emailOtp3: new FormControl(''),
      emailOtp4: new FormControl(''),
      emailOtp5: new FormControl(''),
      emailOtp6: new FormControl(''),
      mobileOtp1: new FormControl(''),
      mobileOtp2: new FormControl(''),
      mobileOtp3: new FormControl(''),
      mobileOtp4: new FormControl(''),
      mobileOtp5: new FormControl(''),
      mobileOtp6: new FormControl(''),

    })
    this.route.queryParams.subscribe(params => {
      this.otpVerificationData = { emailVerified: params['emailVerified'], phoneVerified: params['phoneVerified'] };
    });
  }

  ngOnInit() {
    setTimeout(() => {
      $(document).ready(function () {
        $('input').keyup(function (e) {
          if (e.keyCode == 8) {
            $(this).prev().focus();
          } else if ($(this).val().length == $(this).attr("maxlength")) {
            $(this).next().focus();
          }
        });
      });
    }, 200)
  }

  //integration of check otp verification api
  checkOtpVerification() {
    if (this.otpVerificationData.phoneVerified == 'true') {
      this.showEmailDiv = true
    }
  }

  //integration of send otp api
  sendOtp() {
    let emailOtpValue = this.otpform.value.emailOtp1 + '' + this.otpform.value.emailOtp2 + '' + this.otpform.value.emailOtp3 + '' + this.otpform.value.emailOtp4 + '' + this.otpform.value.emailOtp5 + '' + this.otpform.value.emailOtp6
    let mobileOtpValue = this.otpform.value.mobileOtp1 + '' + this.otpform.value.mobileOtp2 + '' + this.otpform.value.mobileOtp3 + '' + this.otpform.value.mobileOtp4 + '' + this.otpform.value.mobileOtp5 + '' + this.otpform.value.mobileOtp6
    if (emailOtpValue) {
      this.otpValue = emailOtpValue
      this.otpVerification()
    } else {
      this.otpValue = mobileOtpValue
      this.otpVerification()
    }
  }

  //manage via email or phone
  otpVerification() {
    if (this.showEmailDiv == true) {
      this.verifyOtpEmail()
    } else {
      this.verifyOtpPhone();
    }
  }

  //integration of verify otp via phone
  verifyOtpPhone() {
    var formData = this.otpform.value
    if (formData.mobileOtp1 == '' || formData.mobileOtp2 == '' || formData.mobileOtp3 == '' || formData.mobileOtp4 == '' || formData.mobileOtp5 == '' || formData.mobileOtp6 == '') {
      this.service.error('Please enter valid OTP')
    } else {
      this.spinner.show();
      var poolData = {
        UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
        ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
      };
      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: localStorage.getItem('username'),
        Pool: userPool
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmRegistration(this.otpValue, true, (err, result) => {
        this.spinner.hide();
        if (err) {
          if (err.code == 'Unknown error, the response body from fetch is: undefined') {
            this.router.navigate(['/login'])
            this.service.success('Mobile number verified successfully')
          } else if (err.code == 'NotAuthorizedException') {
            this.router.navigate(['/login'])
            this.service.success('Mobile number verified successfully')
          } else {
            this.service.error(err.message)
          }
        } else {
          this.ngOnInit();
          this.showEmailDiv = true;
          this.getUserAttributesFunction();
          this.service.success('Mobile number verified successfully')
        }
      });
    }
  }

  getUserAttributesFunction() {
    var data = {
      UserPoolId: 'us-east-1_FBju9grOp',
      ClientId: '73itd0a6o426vup20afs3aqdna'
    };
    var userPool = new CognitoUserPool(data);
    var userData = {
      Username: localStorage.getItem('username'),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    var authenticationData = {
      Username: localStorage.getItem('username'),
      Password: localStorage.getItem('password')
    };
    var self = this;
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getAttributeVerificationCode('email', {
          onSuccess: function () {
            self.service.success('OTP sent to email successfully')
          },
          onFailure: function (err) {
            alert(err);
          },
          inputVerificationCode: function () {
          }
        });
      },
      onFailure: (err) => {
        this.service.error("Please Provide valid credential")
      }
    });
  }

  //integration of verify otp via email

  verifyOtpEmail() {
    var formData = this.otpform.value
    if (formData.emailOtp1 == '' || formData.emailOtp2 == '' || formData.emailOtp3 == '' || formData.emailOtp4 == '' || formData.emailOtp5 == '' || formData.emailOtp6 == '') {
      this.service.error('Please enter valid OTP')
    } else {
      this.spinner.show();
      var data = {
        UserPoolId: 'us-east-1_FBju9grOp',
        ClientId: '73itd0a6o426vup20afs3aqdna'
      };
      var userPool = new CognitoUserPool(data);
      var userData = {
        Username: localStorage.getItem('username'),
        Pool: userPool
      };
      var cognitoUser = new CognitoUser(userData);
      var authenticationData = {
        Username: localStorage.getItem('username'),
        Password: localStorage.getItem('password')
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          this.spinner.hide();
          localStorage.removeItem('password')
          cognitoUser.verifyAttribute('email', this.otpValue, {
            onSuccess: (result) => {
              this.router.navigate(['/login'])
              this.service.success('Email verified successfully')
            },
            onFailure: (err) => {
              this.service.error("Please Provide valid OTP")
            }
          });
        },
        onFailure: (err) => {
          this.spinner.hide();
        }
      });
    }
  }

  //integration of resend otp via email

  resendOtpEmail() {
    this.getUserAttributesFunction();
  }

  //integration of resend otp via phone

  resendOtpMobile() {
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp',
      ClientId: '73itd0a6o426vup20afs3aqdna'
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem('username'),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    var self = this
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (result) {
        self.service.success('OTP sent to mobile number successfully')
      } else {
        self.service.error('Something Went Wrong')
      }
    });
  }
}
