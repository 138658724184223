import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';
import { Router } from '@angular/router'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';

var copyOfAllUserData = [];

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  unblocEmail: any;
  unblock: any;
  userStatus: any;
  allUsersList: any = [];
  currentPage: number = 1;
  paginationLimit: number = 100;
  filterListData: any = {};
  dropdownFilterData = {
    admin_code: [],
    company_name: [],
    multi_role: [],
    user_type: []
  };
  searchBarData: any;
  filteredUserList: any = [];
  user_type: any
  userList: any
  suspendEmail: any
  suspendedValue: any
  emailId: any;
  userdata: any;
  data = [];
  userId: string;
  email: any;
  fName: any;
  DOB: any;
  companyName: any;
  job: any;
  appAlert: any;
  emailAlert: any;
  userRole: any;
  user: any;
  parentCode: any;
  access: any;
  phoneNmber: any;
  toggle: boolean = true;
  inviteForm: FormGroup;
  user_id: string;
  adminCodeForInvite: any;

  constructor(private service: UserDataService, private router: Router) {
    this.inviteForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required]),
      subject: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(5), Validators.required]),
      adminCodeForInvite: new FormControl('', [Validators.pattern(/^[A-Za-z0-9]*$/), Validators.minLength(4), Validators.required]),
      message: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(5), Validators.required])

    })
  }

  ngOnInit() {
    this.userId = localStorage.getItem('userEmail')
    this.inviteForm.patchValue({ message: "Invite user on Coolsure", subject: "Invite user on Coolsure" })
    this.getUserDataApi();
    this.getUserData();


  }

  profile(email) {
    this.router.navigate(['/user-profile', email])
  }

  editProfile(email) {
    this.router.navigate(['/edit-user-profile/', email])
  }



  getUserDataApi() {
    let data = {
      "function_name": "list_cognito_user",
      "email": localStorage.getItem('userEmail')
    }
    this.service.postApi("/list-cognito-user", data).subscribe(response => {
      if (response['status'] == 200) {



        if (response['body']['errorMessage']) {
          this.service.error("Api response error")
        } else {
          var allUserList = response['body']['data']['Users'];

          this.setAllUsersList(allUserList)
          this.userStatus = allUserList.Enabled

        }
      } else {
        this.service.error("Api response error")
      }
    }, error => {
    })
  }

  Goinvite() {
    this.inviteForm.patchValue({ adminCodeForInvite: this.adminCodeForInvite });
    this.user_id = localStorage.getItem('userId')
  }

  inviteSubmit() {
    var totalInviteMessage = this.inviteForm.value.message + " Please follow the Link for signup https://mean.mobiloitte.com:1420/sign-up?admin_code=" + this.inviteForm.value.adminCodeForInvite
    let data = {
      "function_name": "send_ses_email_user",
      "recipient": this.inviteForm.value.email,
      "message": totalInviteMessage,
      "admin_code": this.inviteForm.value.adminCodeForInvite
    }
    this.service.postApi("/send-ses-email-user", data).subscribe((res) => {
      if (res['status'] == 200) {
        this.service.success('Invitation sent successfully')
        this.inviteForm.reset();
      } else {
        this.service.error(res['statusText'])
      }
    }, (err) => {
    })
  }

  //get data from api and manage data
  getUserData() {
    var self = this;
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem("userEmail"),
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    if (cognitoUser != null) {

      cognitoUser.getSession(function (err, session) {
        cognitoUser.getUserData((err, userData) => {
          userData.UserAttributes.forEach(element => {
            if (element.Name == 'custom:parent_code') {
              self.adminCodeForInvite = element.Value
              this.inviteForm.patchValue({ adminCodeForInvite: this.adminCodeForInvite })
            }
          });
          if (err) {
          }
        });
      });
    }
  }

  setAllUsersList(allUserList) {
    var newUserArr = []
    var newUserObj = {}
    allUserList.forEach(obj => {
      newUserObj = {}
      newUserObj['Enabled'] = obj.Enabled;
      this.userStatus = obj.Enabled;
      obj.Attributes.forEach(attrObj => {
        if (attrObj['Name'] == 'custom:rw') {
          newUserObj['rw'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:company_name') {
          newUserObj['company_name'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:is_email_alert') {
          newUserObj['is_email_alert'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:job_title') {
          newUserObj['job_title'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:multi_role') {
          newUserObj['multi_role'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:admin_code') {
          newUserObj['admin_code'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:date_of_birth') {
          newUserObj['date_of_birth'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:first_name') {
          newUserObj['first_name'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:last_name') {
          newUserObj['last_name'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:is_app_alert') {
          newUserObj['is_app_alert'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:country_name') {
          newUserObj['country_name'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:city_name') {
          newUserObj['city_name'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:parent_code') {
          newUserObj['parent_code'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:phone_number') {
          newUserObj['phone_number'] = attrObj['Value']
        } else if (attrObj['Name'] == 'custom:user_type') {
          if (attrObj['Value'] == '0') {
            newUserObj['user_type'] = ''
          } else if (attrObj['Value'] == '1') {
            newUserObj['user_type'] = 'End User'
          } else if (attrObj['Value'] == '2') {
            newUserObj['user_type'] = 'Service User'
          } else if (attrObj['Value'] == '3') {
            newUserObj['user_type'] = 'Admin'
          } else if (attrObj['Value'] == '4') {
            newUserObj['user_type'] = 'Super Admin'
          }
        } else if (attrObj['Name'] == 'email') {
          newUserObj['email'] = attrObj['Value']
        }
      });

      newUserArr.push(newUserObj)
    });
    copyOfAllUserData = newUserArr
    this.allUsersList = copyOfAllUserData
    this.setFilterDropdownList();
  }

  //Set filter dropdown attributes
  setFilterDropdownList() {
    var adminCodeFilterList = [], companyFilterList = [], roleFilterList = [], userTypeFilterList = []
    copyOfAllUserData.forEach(obj => {
      adminCodeFilterList.push(obj.admin_code.trim())
      companyFilterList.push(obj.company_name.trim())
      userTypeFilterList.push(obj.user_type.trim())
      var currRoleValue = obj.multi_role.split(',')
      currRoleValue.forEach(val => {
        roleFilterList.push(val.trim())
      })
    })
    this.filterListData['adminCodeFilterList'] = this.removeDuplicates(adminCodeFilterList)
    this.filterListData['companyFilterList'] = this.removeDuplicates(companyFilterList)
    this.filterListData['roleFilterList'] = this.removeDuplicates(roleFilterList)
    this.filterListData['userTypeFilterList'] = this.removeDuplicates(userTypeFilterList)
  }

  //remove duplicates
  removeDuplicates(arr) {
    let unique_array = []
    for (let i = 0; i < arr.length; i++) {
      if (unique_array.indexOf(arr[i]) == -1 && arr[i] != 'Undefined' && arr[i] != 'undefined' && arr[i] != '') {
        unique_array.push(arr[i])
      }
    }
    return unique_array
  }

  //manage pagination functionality
  pagination(pageNo) {
    this.currentPage = pageNo
  }

  //apply filter functionality
  applyAllFilter() {
    var filterArray = ['admin_code', 'company_name', 'multi_role', 'user_type']
    this.filteredUserList = copyOfAllUserData
    var count = 0;
    filterArray.forEach(eachKey => {
      var eachKeyValueArr = [], checkArr = [];
      if (this.dropdownFilterData[eachKey].length > 0) {
        this.dropdownFilterData[eachKey].forEach(eachKeyValue => {
          eachKeyValueArr = this.filteredUserList.filter(obj => obj[eachKey].includes(eachKeyValue))
          eachKeyValueArr.forEach(filteredObj => {
            checkArr.push(filteredObj)
          })
        })
        this.searchBarData = ''
        this.filteredUserList = []
        this.filteredUserList = checkArr
        this.allUsersList = []
        this.allUsersList = this.filteredUserList
      } else {
        count++;
        if (count == 4) {
          this.allUsersList = copyOfAllUserData
        }
      }
    })
  }

  //clear data after using filter functionality
  clearFilter() {
    this.dropdownFilterData = {
      admin_code: [],
      company_name: [],
      multi_role: [],
      user_type: []
    };
    this.searchBarData = ''
    this.allUsersList = copyOfAllUserData;
  }

  deleteModal(val) {
    this.emailId = val
  }

  //integration of user delete api
  deletUser() {
    let data = {
      "function_name": "admin_delete_user",
      "username": this.emailId,
    }
    this.service.postApi("/admin-delete-user", data).subscribe((res) => {
      if (res['body']['statusCode'] == '200') {
        this.getUserDataApi()
        this.service.success("user deleted successfully")
      } else {
        this.service.error("Something went Wrong")
      }
    }, (err) => {
      this.service.error("Something went Wrong")
    })
  }

  //manage suspend modal
  suspendModal(val, emailVal) {
    this.suspendedValue = val
    this.suspendEmail = emailVal
  }

  //manage unsuspend modal
  UnsuspendModal(val, emailVal) {
    this.unblock = val
    this.unblocEmail = emailVal
  }

  //manage unblock user
  UnblockUser() {
    var data = {
      "function_name": "admin_enable_user",
      "username": this.unblocEmail,
    }
    this.service.postApi("/admin-enable-user", data).subscribe((res) => {
      if (res['body']['statusCode']) {
        this.service.success(res['body']['data'])
        this.getUserDataApi()
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })
  }

  //manage suspend user
  suspendYes() {
    if (this.suspendedValue) {
      var data = {
        "function_name": "admin_disable_user",
        "username": this.suspendEmail,
      }
    } else {
      var data = {
        "function_name": "admin_enable_user",
        "username": this.suspendEmail,
      }
    }
    this.service.postApi("/admin-disable-user", data).subscribe((res) => {
      if (res['body']['statusCode']) {
        this.service.success(res['body']['data'])
        this.getUserDataApi()
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })
  }

  //manage search functionality
  searchFilter(searchBarVal) {
    this.allUsersList = []
    this.allUsersList = copyOfAllUserData.filter(obj =>
      obj.rw.includes(searchBarVal) ||
      obj.company_name.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.is_email_alert.includes(searchBarVal) ||
      obj.job_title.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.country_name.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.city_name.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.multi_role.includes(searchBarVal) ||
      obj.admin_code.includes(searchBarVal) ||
      obj.date_of_birth.includes(searchBarVal) ||
      obj.first_name.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.last_name.toLowerCase().includes(searchBarVal.toLowerCase()) ||
      obj.is_app_alert.includes(searchBarVal) ||
      obj.parent_code.includes(searchBarVal) ||
      obj.phone_number.includes(searchBarVal) ||
      obj.user_type.includes(searchBarVal) ||
      obj.email.toLowerCase().includes(searchBarVal.toLowerCase()))
  }

  /*************************************** TABLE SORTING ******************************************** */
  compareASC(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property == 'time_stamp') {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      } else {
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
      }
      return result * sortOrder;
    }
  }

  compareDSC(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property == 'time_stamp') {
        var result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      } else {
        var result = (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() < b[property].toLowerCase()) ? 1 : 0;
      }
      return result * sortOrder;
    }
  }

  sortList(sortingKey) {
    if (this.toggle == true) {
      this.allUsersList.sort(this.compareASC(sortingKey));
      this.currentPage = 1;
      this.toggle = false
    } else {
      this.allUsersList.sort(this.compareDSC(sortingKey));
      this.currentPage = 1;
      this.toggle = true
    }
  }
  /*************************************** TABLE SORTING END ******************************************** */

}
