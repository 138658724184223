import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';

@Component({
  selector: 'app-device-information',
  templateUrl: './device-information.component.html',
  styleUrls: ['./device-information.component.css']
})
export class DeviceInformationComponent implements OnInit {
  thingsId: any;
  thingsDetails: any;
  roleName: any;
  showData: boolean;
  roleList: any;
  userType: any;
  thingStorage: any;
  alarmCounter: any;
  thingsArr: any = [];

  constructor(private route: ActivatedRoute, private service: UserDataService) {
    var serialNumber = JSON.parse(localStorage.getItem('allThingsName'))
    this.thingsId = serialNumber[0].thingname;
  }

  ngOnInit() {
    this.userType = localStorage.getItem('userType')
    this.getDeviceDetails()
    this.getAlarmCounter();
  }

  
  // +++++++++++++++++++++++++++ manage data and integration of get Device Details api  ++++++++++++++++++++++++++++

  getDeviceDetails() {
    let data = {
      "function_name": "get_thing_shadow_new",
      "thingnames": [
        { "thingname": this.thingsId }
      ]
    }
    this.service.postApi("/get-thing-shadow-new", data).subscribe((res) => {
      if (res['body']['data']) {
        this.thingsDetails = res['body']['data'][0]
      } else {
        this.service.error("There is no data ")
      }
    }, (err) => {
    })
  }

    // +++++++++++++++++++++++++++ manage data and integration of get Alarm Counter api  ++++++++++++++++++++++++++++

  getAlarmCounter() {
    this.thingStorage = localStorage.getItem('thingStorage')
    let data = {
      "function_name": "device_alarm_status_new",
      "thingname": this.thingStorage
    }
    this.service.postApi("/device-alarm-status-new", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.thingsDetails = res['body']['data']
        var alarmCounter = [];
        this.thingsDetails.forEach(obj => {
          if (obj[2] == '1') {
            if (obj[1][1] == 'Alarm Major') {
              alarmCounter.push(obj[1][0])
            } else if (obj[1][1] == 'Alarm Minor') {
              alarmCounter.push(obj[1][0])
            }
          }
          this.alarmCounter = alarmCounter.length;
        })
      } else {
        this.service.error("There is no data ")
      }
    }, (err) => {
    })
  }
}
