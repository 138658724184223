import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserDataService } from '../../provider/user-data.service';
import { Router } from "@angular/router";
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  jwtToken
  email: any
  user_id: any
  userId: any
  loginUUID: any
  loginToken: any
  loginResponce: any = {}
  loginDetails: any = {}
  loginForm: FormGroup;
  loginData: any = {};
  userName: any;
  userType: any;
  constructor(public service: UserDataService, private router: Router, private spinner: NgxSpinnerService) {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required]),
      password: new FormControl('', [Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.minLength(8), Validators.required]),
      rememberMeValue: new FormControl()
    })
  }
  ngOnInit() {
    var rememberMeCredential = JSON.parse(localStorage.getItem('rememberMeCredential'))
    if (rememberMeCredential != null) {
      this.loginData.email = rememberMeCredential.Username
      this.loginData.password = rememberMeCredential.Password
      this.loginForm.patchValue({ rememberMeValue: true })
    }
  }

  //manage login api 
  login() {
    this.rememberMe();
    this.spinner.show();
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: this.loginData.email,
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    var authenticationData = {
      Username: this.loginData.email,
      Password: this.loginData.password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        this.spinner.hide();
        localStorage.setItem('username', this.loginForm.value.email)
        localStorage.setItem('password', this.loginForm.value.password)
        var emailVerified = result['idToken']['payload']['email_verified']
        var phoneVerified = true
        if (emailVerified === true && phoneVerified === true) {
          localStorage.removeItem('password')
          this.service.success('Logged In Successfully')
          this.jwtToken = result['idToken']['jwtToken']
          localStorage.setItem('jwtToken', this.jwtToken);
          this.userName = result['idToken']['payload']['custom:first_name']
          localStorage.setItem("userName", this.userName)
          localStorage.setItem('userType', result['idToken']['payload']['custom:user_type'])
          this.userId = result['idToken']['payload']['sub']
          this.user_id = localStorage.setItem('userId', this.userId)
          this.email = result['idToken']['payload']['email']
          localStorage.setItem('userEmail', this.email)
          localStorage.setItem('userAccess', result['idToken']['payload']['custom:rw'])
          this.router.navigate(['/dashboard'])
        } 
        else {
          this.router.navigate(['/otp', "sign-up"], { queryParams: { emailVerified: emailVerified, phoneVerified: phoneVerified } })
        }
      },
      onFailure: (err) => {
        this.spinner.hide()
        if (err['message'] == "User is not confirmed.") {
          this.service.error("Please verify OTP")
          this.router.navigate(['/otp', "sign-up"], { queryParams: { emailVerified: true, phoneVerified: false } })
        } else {
          this.service.error("Please Provide valid credential")
        }
      }
    });
  }

  //integration of remember me functionality
  rememberMe() {
    var rememberMeCredential = {
      Username: this.loginData.email,
      Password: this.loginData.password,
    }
    if (this.loginForm.value.rememberMeValue == true) {
      localStorage.setItem('rememberMeCredential', JSON.stringify(rememberMeCredential))
    } else {
      localStorage.removeItem('rememberMeCredential')
    }
  }
}