import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { UserDataService } from '../../provider/user-data.service';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  id = "forgot"
  forgForm: FormGroup
  forgetData: any = {}
  constructor(private router: Router, private service: UserDataService, private spinner: NgxSpinnerService) {
    this.forgForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required])
    })
  }
  ngOnInit() {
  }

  // manage  user forgot passowrd api 
  forgetPassword() {
    this.spinner.show();
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    localStorage.setItem('emailId', this.forgetData.email)
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: this.forgetData.email,
      Pool: userPool
    };
    localStorage.setItem('username', this.forgetData.email)
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        this.spinner.hide();
        this.service.success('OTP sent to your mobile number')
        this.router.navigate(['/reset-password'])
      },
      onFailure: (err) => {
        this.spinner.hide();
        if (err.message == 'the response body from fetch is: undefined') {
          this.service.success('OTP sent to your mobile number')
          this.router.navigate(['/reset-password'])
        } else {
          this.service.error(err.message);
        }
      }
    });
  }
}
