import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms'
import { UserDataService } from '../../provider/user-data.service';
import { IMyDpOptions, IMyDefaultMonth } from 'mydatepicker';
import { Router } from '@angular/router'
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  newCode: any;
  countryList = [];
  id = "sign-up"
  countryCode: any
  checkEmailAlertVal: boolean
  checkAppAlertVal: boolean
  disableBtn: any
  adminObj = {}
  poolData: any
  isDate = true
  mobileNumber: any
  cognitoUser: any
  authSignUpData = []
  AmazonCognitoIdentity: any;
  headersData: any;
  response: any;
  responseData: any;
  signupData: any = {}
  date: any = {}
  signupForm: FormGroup
  adminCode: string;
  now: Date = new Date();
  newMobileNumber: any;
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    disableSince: { year: this.now.getFullYear() - 16, month: this.now.getMonth() + 1, day: this.now.getDate() + 1 },
    showTodayBtn: false,
    monthSelector: true,
    yearSelector: true,
  };
  defaultMonth: IMyDefaultMonth = {
    defMonth: (this.now.getFullYear() - 16) + '/' + (this.now.getMonth() + 1)
  }
  finalParentCode: any = "0"
  countryListJson: any = [];
  countryCodeJson: any = [];

  constructor(public service: UserDataService, private router: Router, private spinner: NgxSpinnerService) {

    this.service.countryListJson.forEach(element => {
      this.countryList.push({
        'name': element.name,
        'countryCode': element.dial_code
      })
    });
    this.signupForm = new FormGroup({
      fname: new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i), Validators.minLength(2), Validators.required]),
      lname: new FormControl('', [Validators.pattern(/^[a-zA-Z ]*$/i), Validators.minLength(2), Validators.required]),
      email: new FormControl('', [Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,3})$/), Validators.required]),
      phoneNumber: new FormControl('', [Validators.pattern(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/), Validators.minLength(8), Validators.required]),
      DOB: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Za-z0-9 _.\-]*$/), Validators.minLength(3), Validators.required]),
      city: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9 _.\-]*$/), Validators.minLength(2), Validators.required]),
      jobTitle: new FormControl('', [Validators.pattern(/^[A-Za-z0-9_.\-]{1}[A-Z a-z0-9_.\-]*$/), Validators.minLength(3), Validators.required]),
      password: new FormControl('', [Validators.minLength(8), Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i), Validators.required]),
      code: new FormControl(''),
      adminCode: new FormControl('', [Validators.pattern(/^[A-Za-z0-9\-]*$/), Validators.minLength(4), Validators.required])
    })
    try {
      this.finalParentCode = window.location.href.split('/')[3].split("?")[1].split("=")[1];
      this.adminObj['adminCode'] = this.finalParentCode
    } catch (ex) {
    }
  }
  goLogin() {
    this.router.navigate(['/login'])

  }
  ngOnInit() {
    this.checkAppAlertVal = false
    this.checkEmailAlertVal = false
    $("#emailid").on("keypress", function (e) {
      if (e.which === 32 && !this.value.length)
        e.preventDefault();
    });
  }
  // +++++++++++++++++++++++++++ app alert fucntionlity  ++++++++++++++++++++++++++++

  appAlertClick() {
    this.checkAppAlertVal = $('#app_alert').is(':checked') ? true : false
  }
  // +++++++++++++++++++++++++++ email alerty fucntionlity ++++++++++++++++++++++++++++

  emailAlertClick() {
    this.checkEmailAlertVal = $('#email_alert').is(':checked') ? true : false
  }

  // +++++++++++++++++++++++++++ Signup  api integration ++++++++++++++++++++++++++++

  signUp() {
    this.mobileNumber = this.signupForm.value.code + '-' + this.signupForm.value.phoneNumber;
    this.newMobileNumber = this.signupForm.value.code.concat(this.signupForm.value.phoneNumber);
    this.spinner.show();
    var poolData = {
      UserPoolId: 'us-east-1_FBju9grOp', // Your user pool id here
      ClientId: '73itd0a6o426vup20afs3aqdna' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var attributeList = [];
    var dataEmail = {
      Name: 'email',
      Value: this.signupForm.value.email
    };
    var dataPhoneNumber = {
      Name: 'custom:phone_number',
      Value: this.mobileNumber,
    };
    var simplePhoneNumber = {
      Name: 'phone_number',
      Value: this.newMobileNumber,
    };
    var firstName = {
      Name: 'custom:first_name',
      Value: this.signupForm.value.fname,
    };
    var lastName = {
      Name: 'custom:last_name',
      Value: this.signupForm.value.lname,
    };
    var dateOfBirth = {
      Name: 'custom:date_of_birth',
      Value: this.date.DOB.formatted,
    };
    var jobTitle = {
      Name: 'custom:job_title',
      Value: this.signupForm.value.jobTitle,
    };
    var appAlert = {
      Name: 'custom:is_app_alert',
      Value: this.checkAppAlertVal == true ? '1' : '0',
    };
    var emailAlert = {
      Name: 'custom:is_email_alert',
      Value: this.checkEmailAlertVal == true ? '1' : '0',
    };
    var adminCode = {
      Name: 'custom:admin_code',
      Value: this.signupForm.value.adminCode,
    };
    var parentCode = {
      Name: 'custom:parent_code',
      Value: this.signupForm.value.adminCode,
    };
    var companyName = {
      Name: 'custom:company_name',
      Value: this.signupForm.value.companyName,
    };
    var country = {
      Name: 'custom:country_name',
      Value: this.signupForm.value.country,
    };
    var city = {
      Name: 'custom:city_name',
      Value: this.signupForm.value.city,
    };
    var userType = {
      Name: 'custom:user_type',
      Value: '1',
    };
    var role = {
      Name: 'custom:role',
      Value: '0',
    };
    var multiRole = {
      Name: 'custom:multi_role',
      Value: '0',
    };
    var rw = {
      Name: 'custom:rw',
      Value: '1',
    };
    var attributeEmail = new CognitoUserAttribute(dataEmail);
    var attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
    attributeList.push(attributeEmail);
    attributeList.push(attributePhoneNumber);
    attributeList.push(firstName);
    attributeList.push(simplePhoneNumber);
    attributeList.push(lastName);
    attributeList.push(dateOfBirth);
    attributeList.push(jobTitle);
    attributeList.push(appAlert);
    attributeList.push(emailAlert);
    attributeList.push(adminCode);
    attributeList.push(companyName);
    attributeList.push(parentCode);
    attributeList.push(userType);
    attributeList.push(role);
    attributeList.push(multiRole);
    attributeList.push(rw);
    attributeList.push(country);
    attributeList.push(city);
    localStorage.setItem('username', this.signupForm.value.email)
    localStorage.setItem('password', this.signupForm.value.password)
    userPool.signUp(this.signupForm.value.email, this.signupForm.value.password, attributeList, null, (err, result) => {
      this.spinner.hide();
      if (!err) {
        this.service.success("User registered successfully")
        this.router.navigate(['/otp', this.id])
      } else {
        if (err.message == 'Unknown error, the response body from fetch is: undefined') {
          this.service.success("User registered successfully")
          this.router.navigate(['/otp', this.id])
        } else {
          this.service.error(err.message)
        }
      }
    });
  }

  //change country and patch value of particular country code
  countryChange(data) {
    this.signupForm.patchValue({
      'code': data
    })
  }
}
