import { Component, OnInit, ViewChild } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {
  name = 'ng2-ckeditor';
  ckeConfig: any;
  mycontent: string;
  log: string = '';
  @ViewChild("myckeditor") ckeditor: any;
  title: any;
  description: any;
  web_title: any;
  web_description: any;
  appId: any;
  webId: any;
  showEditDiv: boolean = false;
  userType: any;
  mainViewData: any;

  constructor(private service: UserDataService) {
    this.mycontent = `<p>My html content</p>`;
  }
  ngOnInit() {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    }
    this.getStaticContent()
  }

  editStaticContent() {
    this.showEditDiv = true;
  }

  backStaticContent() {
    this.showEditDiv = false;
  }

  //get static content from api 

  getStaticContent() {
    let data = {
      "function_name": "get_terms_conditions"
    }
    this.service.postApi("/get-terms-conditions", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.title = res['body']['body'][0]['title']
        this.description = res['body']['body'][0]['description']
        this.web_title = res['body']['body'][1]['web_title']
        this.web_description = res['body']['body'][1]['web_description']
        this.appId = res['body']['body'][0]['id']
        this.webId = res['body']['body'][1]['id']
        this.userType = res['body']['user_type']
        this.mainViewData = res['body']['body'][1]['web_description']
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })
  }

  saveContent(newKey, contentDesription, id) {
    let data = {
      "function_name": "update_terms_conditions",
      [newKey]: contentDesription,
      "id": id
    }
    this.service.postApi("/update-terms-conditions", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.showEditDiv = false;
        this.getStaticContent();
        this.service.success(res['body']['responseMessage'])
      } else {
        this.service.error("Api Response Error ")
      }
    }, (err) => {
      this.service.error("Something Went Wrong")
    })


  }

}
