import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { UserDataService } from '../../provider/user-data.service';

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.css']
})
export class AlarmComponent implements OnInit {
  thingsName: any
  thingsDetails: any
  thingStorage: string;
  userType: any;
  minorAlarmArr: any = [];
  majorAlarmArr: any = [];
  airconSerialNumber: any;
  thingsArr: any = [];
  constructor(private router: Router, private route: ActivatedRoute, private service: UserDataService) {
    var serialNumber = JSON.parse(localStorage.getItem('allThingsName'))
    this.thingStorage = serialNumber[0].thingname;
    this.airconSerialNumber = localStorage.getItem('airconSerial')
  }

  ngOnInit() {
    this.userType = localStorage.getItem('userType')
    this.getDeviceDetails()
  }

  // get Device Details of particular user
  getDeviceDetails() {
    let data = {
      "function_name": "device_alarm_status_new",
      "thingname": this.thingStorage
    }
    this.service.postApi("/device-alarm-status-new", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.thingsDetails = res['body']['data']
        this.thingsDetails.forEach(obj => {
          if (obj[2] == '1') {
            if (obj[1][1] == 'Alarm Major') {
              this.majorAlarmArr.push(obj[1][0])
            } else if (obj[1][1] == 'Alarm Minor') {
              this.minorAlarmArr.push(obj[1][0])
            }
          }
        })
      } else {
        this.service.error("There is no data")
      }
    }, (err) => {
    })
  }

}
