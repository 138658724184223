import { Component } from '@angular/core';
import { UserDataService } from './provider/user-data.service';
import { Router, NavigationEnd } from '../../node_modules/@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isActive
  previousUrl: string;
  title = 'app';
  newUrl
  things: string;
  constructor(private service: UserDataService, private router: Router) {
    this.newUrl = window.location.href.split('/')[3]
    localStorage.setItem('tempUrl', this.newUrl)
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.newUrl = x.url.split('/')[1]
        this.things = x.url.split('/')[2]
        localStorage.setItem('thingStorage', this.things)
        window.scroll(0, 0)
        if (localStorage.getItem('userId') == null) {
          this.newUrl = this.newUrl.split("?")[0];
          if (!(this.newUrl == 'login' || this.newUrl == 'sign-up' || this.newUrl == 'forget-password' || this.newUrl == 'otp' || this.newUrl == 'reset-password' || this.newUrl == 'services')) {
            this.router.navigate(['/login']);
          }
        } else {
          if (this.newUrl == 'login' || this.newUrl == 'sign-up' || this.newUrl == 'forget-password' || this.newUrl == 'otp' || this.newUrl == 'reset-password' || this.newUrl == '') {
            this.router.navigate(['/dashboard'])
          }
        }
      }
    })
  }

}
