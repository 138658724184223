import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsModalModule } from 'ng2-bs3-modal';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as $ from 'jquery';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { AlarmComponent } from './pages/alarm/alarm.component';
import { DefineRoleComponent } from './pages/define-role/define-role.component';
import { DeviceDashboardComponent } from './pages/device-dashboard/device-dashboard.component';
import { DeviceInformationComponent } from './pages/device-information/device-information.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { EditRoleComponent } from './pages/edit-role/edit-role.component';
import { EditUserProfileComponent } from './pages/edit-user-profile/edit-user-profile.component';
import { GraphComponent } from './pages/graph/graph.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { OtpComponent } from './pages/otp/otp.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RoleManagementComponent } from './pages/role-management/role-management.component';
import { SelectedFieldComponent } from './pages/selected-field/selected-field.component';
import { SettingComponent } from './pages/setting/setting.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { SideBarComponent } from './pages/side-bar/side-bar.component';
import { HeaderComponent } from './pages/header/header.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserDataService, httpModifierInterceptor } from './provider/user-data.service';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { Ng5SliderModule } from 'ng5-slider';
import { RoleDetailComponent } from './pages/role-detail/role-detail.component';
import { ChartModule } from 'angular2-highcharts';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { MatAutocompleteModule, MatInputModule, MatFormFieldModule } from '@angular/material';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { StaticContentComponent } from './pages/static-content/static-content.component';


declare var require: any;


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    DashboardComponent,
    ChangePasswordComponent,
    ForgetPasswordComponent,
    AlarmComponent,
    DefineRoleComponent,
    DeviceDashboardComponent,
    DeviceInformationComponent,
    EditProfileComponent,
    EditRoleComponent,
    EditUserProfileComponent,
    GraphComponent,
    MyProfileComponent,
    OtpComponent,
    ResetPasswordComponent,
    RoleManagementComponent,
    SelectedFieldComponent,
    SettingComponent,
    SummaryComponent,
    TermConditionComponent,
    UserManagementComponent,
    UserProfileComponent,
    SideBarComponent,
    HeaderComponent,
    RoleDetailComponent,
    NotFoundComponent,
    ContactUsComponent,
    StaticContentComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MyDatePickerModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    BsModalModule,
    CKEditorModule,
    NgxSpinnerModule,
    AmazingTimePickerModule,
    Ng5SliderModule,
    NgMultiSelectDropDownModule.forRoot(),
    ChartModule.forRoot(require('highcharts')),
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [UserDataService, { provide: HTTP_INTERCEPTORS, useClass: httpModifierInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
