import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './pages/sign-up/sign-up.component'
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { AlarmComponent } from './pages/alarm/alarm.component';
import { DefineRoleComponent } from './pages/define-role/define-role.component';
import { DeviceDashboardComponent } from './pages/device-dashboard/device-dashboard.component';
import { DeviceInformationComponent } from './pages/device-information/device-information.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { EditRoleComponent } from './pages/edit-role/edit-role.component';
import { EditUserProfileComponent } from './pages/edit-user-profile/edit-user-profile.component';
import { GraphComponent } from './pages/graph/graph.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { OtpComponent } from './pages/otp/otp.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RoleManagementComponent } from './pages/role-management/role-management.component';
import { SelectedFieldComponent } from './pages/selected-field/selected-field.component';
import { SettingComponent } from './pages/setting/setting.component';
import { SummaryComponent } from './pages/summary/summary.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { HeaderComponent } from './pages/header/header.component';
import { SideBarComponent } from './pages/side-bar/side-bar.component';
import { RoleDetailComponent } from './pages/role-detail/role-detail.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { StaticContentComponent } from './pages/static-content/static-content.component';



const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'alarm/:thingsDetails.thingname', component: AlarmComponent },
  { path: 'define-role', component: DefineRoleComponent },
  { path: 'device-dashboard/:dashData.thingname', component: DeviceDashboardComponent },
  { path: 'device-information/:thingsDetails.thingname', component: DeviceInformationComponent },
  { path: 'edit-profile', component: EditProfileComponent },
  { path: 'edit-role/:val', component: EditRoleComponent },
  { path: 'edit-user-profile/:email', component: EditUserProfileComponent },
  { path: 'graph', component: GraphComponent },
  { path: 'my-profile', component: MyProfileComponent },
  { path: 'otp/:id', component: OtpComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'selected-field', component: SelectedFieldComponent },
  { path: 'setting/:thithingsDetailsngname', component: SettingComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'term-condition', component: TermConditionComponent },
  { path: 'user-management', component: UserManagementComponent },
  { path: 'user-profile/:email', component: UserProfileComponent },
  { path: 'role-detail/:val', component: RoleDetailComponent },
  { path: 'role-management', component: RoleManagementComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'contactUs', component: ContactUsComponent },
  { path: 'services/:type', component: StaticContentComponent},
  { path: '', component: SideBarComponent, outlet: "side-bar" },
  { path: '*', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent },
  { path: '***', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
