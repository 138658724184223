import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { BsModalComponent } from 'ng2-bs3-modal';
declare var $: any;
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  // role = 'service'
  // role = 'superadmin'
  isActive1: any
  userName: any
  userType: any
  type: string;
  constructor(private router: Router) {
    this.userType = localStorage.getItem("userType")
    if (this.userType == '1') {
      this.type = 'endUser'
    } else if (this.userType == '2') {
      this.type = 'serviceUser'
    } else if (this.userType == '3') {
      this.type = 'admin'
    } else if (this.userType == '4') {
      this.type = 'superAdmin'
    }
    this.userName = localStorage.getItem("userName")
    this.isActive1 = window.location.href.split('/')[3]
  }
  ngOnInit() {
  }
    // +++++++++++++++++++++++++++ manage side bar navigation  ++++++++++++++++++++++++++++

  goDashboard() {
    this.isActive1 = 'dashboard'
    this.router.navigate(['/dashboard'])
  }
  goUserManagement() {
    this.isActive1 = 'user-management'
    this.router.navigate(['/user-management'])
  }
  goRoleManagement() {
    this.isActive1 = 'role-management'
    this.router.navigate(['/role-management'])
  }
  goTermCondition() {
    this.isActive1 = 'term-condition'
    this.router.navigate(['/term-condition'])
  }

  goContactUs() {
    this.isActive1 = 'contactUs'
    this.router.navigate(['/contactUs'])
  }

  //manage logout functionality
  logOut() {
    var poolData = {
      UserPoolId: 'us-east-1_lOiNikq5p', // Your user pool id here
      ClientId: '2c0o5f4oouheeas182cau1e7s1' // Your client id here
    };
    var userPool = new CognitoUserPool(poolData);
    var userData = {
      Username: localStorage.getItem('userEmail'),
      // Value : localStorage.getItem('emailId') !=null ? localStorage.getItem('emailId') :'arvindagrahari107@gmail.com',
      Pool: userPool
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.signOut();
    var rememberMeCredential = JSON.parse(localStorage.getItem('rememberMeCredential'))
    localStorage.clear();
    localStorage.setItem('rememberMeCredential', JSON.stringify(rememberMeCredential))
    this.router.navigate(['/login'])
  }
}