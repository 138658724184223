import { Component, OnInit, ApplicationRef } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
declare var $: any;
@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.css']
})
export class EditRoleComponent implements OnInit {
  userArry: any = [];
  permisson: any;
  appAltchek: any;
  emailAltchek: any;
  is_send_rw: any
  is_send_apl: any;
  is_send_eml: any;
  checkboxVal: any
  filData = {}
  editeData = {}
  dataArr = []
  newUserArr = []
  newCountryArr = []
  customerArry = []
  newCityArr = []
  newSnArr = []
  newUnitArr = []
  newRoomArr = []
  newSiteArr = []
  userReq = {}
  edieRoleData: any
  roleObj: any
  id: any
  roleData = {}
  profileObj: any
  selectCountry: any;
  disabled: boolean = false;
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: '5',
    allowSearchFilter: true
  };
  dropdownList: any;
  user: any;
  url: any
  tokenId: any;
  totalData = [];
  totalDataArray = []
  dashboardDetals = []
  temp: any;
  showData: boolean;
  disableFilterOnchange: boolean;
  is_rw: any;
  permissionObj: any = ''
  appAlt: any;
  emailAlt: any
  constructor(private appreff: ApplicationRef, private service: UserDataService, private route: ActivatedRoute, private router: Router) {
    this.showData = true;
    this.roleData = {
      "user_name": new Array<string>(),
      "customer_name": new Array<string>(),
      "country": new Array<string>(),
      "city": new Array<string>(),
      "site": new Array<string>(),
      "room": new Array<string>(),
      "unit_name": new Array<string>(),
      "SN": new Array<string>()

    }
    this.editeData = {
      "user_name": new Array<string>(),
      "customer_name": new Array<string>(),
      "country": new Array<string>(),
      "city": new Array<string>(),
      "site": new Array<string>(),
      "room": new Array<string>(),
      "unit_name": new Array<string>(),
      "SN": new Array<string>()
    }
    this.temp = this.roleData["customer_name"];

  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params.val
    })
    this.editRole()

  }

  // get edit role of user 

  editRole() {
    let data = {
      "function_name": "get_user_role_detail",
      "id": this.id
    }
    this.service.postApi("/get-user-role-detail", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.edieRoleData = res['body']['body']
        var finalArray = [];
        for (var i in this.edieRoleData) {
          var obj = {};
          for (var key in this.edieRoleData[i]) {
            if (typeof this.edieRoleData[i][key] == 'object') {
              obj[key] = [];
              for (var innerKey in this.edieRoleData[i][key]) {
                obj[key].push(innerKey)
              }
            } else {
              obj[key] = this.edieRoleData[i][key]
            }
          }
          finalArray.push(obj)
          this.editeData = finalArray[0]
          this.is_rw = this.editeData['rw'] == 2 ? true : false;
          this.appAlt = this.editeData['is_app_alert'] == true ? true : false;
          this.emailAlt = this.editeData['is_email_alert'] == true ? true : false;
          this.permissionObj = this.editeData['is_permission'];
        }
        if (localStorage.userType == '3') {
          this.disabled = true;
        } else {
          this.disabled = false;
        }

      }

      else {
        this.service.error("Something Went Wrong")
      }
    }, (err) => {
    })
  }

  // +++++++++++++++++++++++++++  check value of checkbox  ++++++++++++++++++++++++++++

  clickCheck() {
    this.checkboxVal = $('#checkbox1').is(':checked') ? true : false
    this.is_rw = this.checkboxVal;
  }
  // +++++++++++++++++++++++++++  check mail value of checkbox  ++++++++++++++++++++++++++++

  checkedemailAlt() {
    this.emailAltchek = $('#checkbox2').is(':checked') ? true : false
    this.emailAlt = this.emailAltchek;
  }
  // +++++++++++++++++++++++++++  check App value of checkbox  ++++++++++++++++++++++++++++


  checkedappAlt() {
    this.appAltchek = $('#checkbox3').is(':checked') ? true : false
    this.appAlt = this.appAltchek;
  }

  userAccess(val) {
    this.permisson = val
  }


  // Update role of user 

  updateRole(roleName, Customer, userName, Country, City, Site, Room, Unit, SN, id) {
    this.is_send_rw = this.is_rw == true ? 2 : 1
    this.is_send_apl = this.appAlt == true ? 2 : 1
    this.is_send_eml = this.emailAlt == true ? 2 : 1

    var customerObj = {}
    for (var i in Customer) {
      customerObj[Customer[i]] = "S"
    }

    var userObj = {}
    for (var i in userName) {
      userObj[userName[i]] = "S"
    }

    var CountryObj = {}
    for (var i in Country) {
      CountryObj[Country[i]] = "S"
    }
    var CityObj = {}
    for (var i in City) {
      CityObj[City[i]] = "S"
    }
    var SiteObj = {}
    for (var i in Site) {
      SiteObj[Site[i]] = "S"
    }
    var RoomObj = {}
    for (var i in Room) {
      RoomObj[Room[i]] = "S"
    }
    var UnitObj = {}
    for (var i in Unit) {
      UnitObj[Unit[i]] = "S"
    }
    var SNObj = {}
    for (var i in SN) {
      SNObj[SN[i]] = "S"
    }
    if (this.editeData['customer_name'] == '') {
      this.service.error('Please select any customer')
      return
    }
    let data =
    {
      "function_name": "update_role_detail",
      "email": localStorage.getItem('userEmail'),
      "id": id,
      "roleDetails": {
        "role_name": roleName,
        "customer_name": customerObj,
        "user_name": userObj,
        "country": CountryObj,
        "city": CityObj,
        "site": SiteObj,
        "room": RoomObj,
        "unit_name": UnitObj,
        "SN": SNObj,
        "rw": this.is_send_rw,
        "is_app_alert": this.appAlt,
        "is_email_alert": this.emailAlt,
        "is_permission": this.permisson
      }
    }
    this.service.postApi("/update-role-detail", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.router.navigate(['/role-management'])
        this.service.success('Role updated successfully')
      } else {
        this.service.error('Api Response Error')
      }
    }, (err) => {
      this.service.error('Something Went Wrong')
    })
  }
  filetByUrl(event) {
    var username = event.target.value
  }

  async userChange(checkVal) {
    if (this.disableFilterOnchange)
      return;
    this.showData = false;
    if (checkVal == 1) {
      this.roleData["user_name"]
      this.roleData["country"] = [];
      this.roleData["city"] = [];
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];

      this.editeData['user_name']
      this.editeData['country'] = [];
      this.editeData['city'] = [];
      this.editeData['site'] = [];
      this.editeData['room'] = [];
      this.editeData['unit_name'] = [];
      this.editeData['SN'] = [];
    }
    if (checkVal == 2) {
      this.roleData["country"] = [];
      this.roleData["city"] = [];
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];

      this.editeData['country'] = [];
      this.editeData['city'] = [];
      this.editeData['site'] = [];
      this.editeData['room'] = [];
      this.editeData['unit_name'] = [];
      this.editeData['SN'] = [];
    }
    if (checkVal == 3) {
      this.roleData["city"] = [];
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];

      this.editeData['city'] = [];
      this.editeData['site'] = [];
      this.editeData['room'] = [];
      this.editeData['unit_name'] = [];
      this.editeData['SN'] = [];
    }
    if (checkVal == 4) {
      this.roleData["site"] = [];
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];

      this.editeData['site'] = [];
      this.editeData['room'] = [];
      this.editeData['unit_name'] = [];
      this.editeData['SN'] = [];
    }
    if (checkVal == 5) {
      this.roleData["room"] = [];
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];

      this.editeData['room'] = [];
      this.editeData['unit_name'] = [];
      this.editeData['SN'] = [];
    }
    if (checkVal == 6) {
      this.roleData["unit_name"] = [];
      this.roleData["SN"] = [];

      this.editeData['unit_name'] = [];
      this.editeData['SN'] = [];
    }
    if (checkVal == 7) {
      this.roleData["SN"] = [];

      this.editeData['SN'] = [];
    }

    var custArry = []
    for (let i in this.editeData['customer_name']) {
      custArry.push({ "value": this.editeData['customer_name'][i] })
    }
    this.customerArry = custArry

    var userArry = []
    for (let i in this.editeData["user_name"]) {
      userArry.push({ "value": this.editeData['user_name'][i] })
    }
    this.userArry = userArry

    var countryArr = []
    for (let i in this.editeData['country']) {
      countryArr.push({ "value": this.editeData['country'][i] })
    }
    this.newCountryArr = countryArr

    var cityArr = []
    for (let i in this.editeData['city']) {
      cityArr.push({ "value": this.editeData['city'][i] })

    }
    this.newCityArr = cityArr

    var siteArr = []
    for (let i in this.editeData['site']) {
      siteArr.push({ "value": this.editeData['site'][i] })
    }
    this.newSiteArr = siteArr

    var roomArr = []
    for (let i in this.editeData['room']) {
      roomArr.push({ "value": this.editeData['room'][i] })
    }
    this.newRoomArr = roomArr

    var snArr = []
    for (let i in this.editeData['SN']) {
      snArr.push({ "value": this.editeData['SN'][i] })
    }
    this.newSnArr = snArr
    this.filData = {

      "function_name": "filter_device_details",
      "email": localStorage.getItem('userEmail'),
      "customer": this.customerArry,
      "user": this.userArry,
      "country": this.newCountryArr,
      "city": this.newCityArr,
      "site": this.newSiteArr,
      "room": this.newRoomArr,
      "unit": this.newUnitArr,
      "SN": this.newSnArr
    }
    this.showData = true;
    this.disableFilterOnchange = true;
    this.showData = false;
    let resp = await this.callAPI(this.filData);
  }
  callAPI(data): Promise<any> {
    return new Promise((resolv, reject) => {
      this.getFilterdata(data);
      resolv("");
    });
  }

  // get filter data 

  getFilterdata(data) {
    this.service.postApi("/filter-device-details", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.dataArr = []
        this.dataArr = res['body']['data']
        for (var i in this.dataArr) {
          if (!this.roleData["customer_name"].includes(this.dataArr[i]["customer"])) {
            this.roleData["customer_name"].push(this.dataArr[i]["customer"])
          }
          if (!this.roleData["user_name"].includes(this.dataArr[i]["person"])) {
            this.roleData["user_name"].push(this.dataArr[i]["person"])
          }
          if (!this.roleData["country"].includes(this.dataArr[i]["country"])) {
            this.roleData["country"].push(this.dataArr[i]["country"])
          }
          if (!this.roleData["city"].includes(this.dataArr[i]["city"])) {
            this.roleData["city"].push(this.dataArr[i]["city"])
          }
          if (!this.roleData["unit_name"].includes(this.dataArr[i]["unit_type"])) {
            this.roleData["unit_name"].push(this.dataArr[i]["unit_type"])
          }
          if (!this.roleData["room"].includes(this.dataArr[i]["room"])) {
            this.roleData["room"].push(this.dataArr[i]["room"])
          }
          if (!this.roleData["site"].includes(this.dataArr[i]["site"])) {
            this.roleData["site"].push(this.dataArr[i]["site"])
          }
          if (!this.roleData["SN"].includes(this.dataArr[i]["SN"])) {
            this.roleData["SN"].push(this.dataArr[i]["SN"])
          }
        }
        this.showData = true;
        setTimeout(() => {
          this.disableFilterOnchange = false;
        }, 1000);
      }
      else if (res['body']['statusCode'] == 400) {
      } else {
      }
    }, (err) => {
    })
  }

  cancel() {
    this.router.navigate(['/role-management'])
  }
}


