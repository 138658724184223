import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  energyMeter: any;
  average_out_temprature: any
  units: any
  thingsDetails: any;
  thingName = []
  summeryData: any;
  totalPower: any;
  average_room_temprature: any;
  average_room_humidity: any;
  total_power: any;
  average_aircon_power: any;
  major_alarm: any;
  minor_alarm: any;

  constructor(private service: UserDataService) {

  }

  ngOnInit() {
    this.summeryApi();
  }

  //get summery data and manage data
  summeryApi() {
    let data = {
      "function_name": "group_summary_new",
      "thingnames": JSON.parse(localStorage.getItem('allThingsName'))
    }
    this.service.postApi("/group-summary-new", data).subscribe((res) => {
      if (res['status'] == 200) {
        this.summeryData = res['body']
        this.total_power = this.summeryData['summary']['total_power']
        this.energyMeter = this.summeryData['summary']['energyMeter']
        this.average_room_temprature = this.summeryData['summary']['average_room_temprature']
        this.average_room_humidity = this.summeryData['summary']['average_room_humidity']
        this.average_aircon_power = this.summeryData['summary']['average_aircon_power']
        this.average_out_temprature = this.summeryData['summary']['average_out_temprature']
        this.units = this.summeryData['summary']['unit']
        this.major_alarm = this.summeryData['summary']['major_alarm']
        this.minor_alarm = this.summeryData['summary']['minor_alarm']
      } else {
        this.service.error(res['statusText'])
      }
    }, (err) => {
      this.service.error('Something Went Wrong')
    })
  }

}
