import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../../provider/user-data.service'
import { Router } from '../../../../node_modules/@angular/router';
declare var $: any;
var copyOfAllData = [];


@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit {
  unsuspendVal: any;
  unsuspendId: any;
  currentPage: number = 1;
  paginationLimit: number = 100;
  newSNArr = []
  newRoomArr = []
  newSiteArr = []
  newCityArr = []
  newCountryArr = []
  newUserNameArr = []
  newCustomerNameArr = []
  roleDetails: any
  roleArr = []
  newRoleArray = []
  roleFilterData: any
  noData: boolean
  finaldata: any
  userResponse: any
  userDetails: any
  currPage = 1
  thingsDetails: any;
  thingName = []
  thingsData: any;
  suspendVal: any
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  roleData: any;
  deleteId: void;
  suspendId: any;
  isSus: any;
  roleDataDetails: any;
  role = []
  roleLength: any;
  serachFilter: any;
  toggle: boolean = true;
  searchBarData: any;
  dropdownSettings = {
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: true,
    idField: 'item_id',
    textField: 'item_text'
  };
  typeOfUser: any;

  constructor(private service: UserDataService, private router: Router) {
    this.typeOfUser = localStorage.getItem('userType')
    this.roleFilterData = {
      "role_name": new Array<string>(),
      "customer_name": new Array<string>(),
      "user_name": new Array<string>(),
      "country": new Array<string>(),
      "city": new Array<string>(),
      "site": new Array<string>(),
      "room": new Array<string>(),
      "unit_name": new Array<string>(),
      "SN": new Array<string>(),
    }
    this.roleData = {
      "user_role": new Array<string>(),
      "customer_name": new Array<string>(),
      "user_name": new Array<string>(),
      "country": new Array<string>(),
      "city": new Array<string>(),
      "site": new Array<string>(),
      "room": new Array<string>(),
      "unit_name": new Array<string>(),
      "SN": new Array<string>(),
    }
    this.roleDetails = {
      "role_name": new Array<string>(),
      "customer_name": new Array<string>(),
      "user_name": new Array<string>(),
      "country": new Array<string>(),
      "city": new Array<string>(),
      "site": new Array<string>(),
      "room": new Array<string>(),
      "unit_name": new Array<string>(),
      "SN": new Array<string>(),
    }

  }
  ngOnInit() {
    this.noData = true
    this.getRoleApi()
  }

  //get data from api and manage data
  getRoleApi() {
    let data = {
      "function_name": 'get_roles_details',
    }
    this.service.postApi("/get-roles-details", data).subscribe(response => {
      if (response['status'] == 200) {
        this.roleDataDetails = response['body']['body'];
        this.setAllRoleList()
        localStorage.setItem('typeOfUser', response['type'])
      } else {
        this.service.error("Api response error")
      }
    }, error => {
    })
  }

  //maanage and remoce duplicate attributes
  removeDuplicates(arr) {
    let unique_array = []
    for (let i = 0; i < arr.length; i++) {
      if (unique_array.indexOf(arr[i]) == -1 && arr[i] != 'Undefined' && arr[i] != 'undefined') {
        unique_array.push(arr[i])
      }
    }
    return unique_array
  }

  //navigate to view role deatils
  roleDetail(id) {
    this.router.navigate(['/role-detail', id])
  }

  //manage serach functionality
  searchFilter(searchBarVal) {
    this.finaldata = []
    this.finaldata = copyOfAllData.filter(obj =>
      obj.role_name.toLowerCase().includes(searchBarVal.toLowerCase())
    )
  }

  //set all role list 
  setAllRoleList() {
    this.roleDetails = this.roleDataDetails
    var finalArray = [];
    for (var i in this.roleDetails) {
      var obj = {};
      for (var key in this.roleDetails[i]) {
        if (typeof this.roleDetails[i][key] == 'object') {
          obj[key] = [];
          for (var innerKey in this.roleDetails[i][key]) {
            obj[key].push(innerKey)
          }
        } else {
          obj[key] = this.roleDetails[i][key]
        }
      }
      finalArray.push(obj)
    }
    copyOfAllData = finalArray
    this.finaldata = copyOfAllData;
    this.setFilterDropdownList();
  }

  setFilterDropdownList() {
    var customerFilterList = [], userFilterList = [], countryFilterList = [], cityFilterList = [], siteFilterList = [], roomFilterList = []
    copyOfAllData.forEach(obj => {
      obj.customer_name.forEach(val => {
        customerFilterList.push(val.trim())
      })
      obj.user_name.forEach(val => {
        userFilterList.push(val.trim())
      })
      obj.country.forEach(val => {
        countryFilterList.push(val.trim())
      })
      obj.city.forEach(val => {
        cityFilterList.push(val.trim())
      })
      obj.site.forEach(val => {
        siteFilterList.push(val.trim())
      })
      obj.room.forEach(val => {
        roomFilterList.push(val.trim())
      })
    })
    this.roleData['customer_name'] = this.removeDuplicates(customerFilterList)
    this.roleData['user_name'] = this.removeDuplicates(userFilterList)
    this.roleData['country'] = this.removeDuplicates(countryFilterList)
    this.roleData['city'] = this.removeDuplicates(cityFilterList)
    this.roleData['site'] = this.removeDuplicates(siteFilterList)
    this.roleData['room'] = this.removeDuplicates(roomFilterList)
  }

  // apply filter functionlaity
  applyAllFilter() {
    var filterArray = ['customer_name', 'user_name', 'country', 'city', 'site', 'room']
    var filteredUserList = copyOfAllData
    var newArr = []
    var filterArr = []
    filterArray.forEach(val => {
      if (this.roleFilterData[val] != '') {
        filterArr.push(val)
      }
    })
    filteredUserList.forEach(obj => {
      if (filterArr.length == 1) {
        var firstCount = 0;
        this.roleFilterData[filterArr[0]].forEach(val => {
          if (obj[filterArr[0]].includes(val)) {
            firstCount++;
          }
        })
        if (firstCount == this.roleFilterData[filterArr[0]].length) {
          newArr.push(obj)
        }
      } else if (filterArr.length == 2) {
        var firstCount = 0, secondCount = 0;
        this.roleFilterData[filterArr[0]].forEach(val => {
          if (obj[filterArr[0]].includes(val)) {
            firstCount++;
          }
        })
        this.roleFilterData[filterArr[1]].forEach(val => {
          if (obj[filterArr[1]].includes(val)) {
            secondCount++;
          }
        })
        if (firstCount == this.roleFilterData[filterArr[0]].length && secondCount == this.roleFilterData[filterArr[1]].length) {
          newArr.push(obj)
        }
      } else if (filterArr.length == 3) {
        var firstCount = 0, secondCount = 0, thirdCount = 0;
        this.roleFilterData[filterArr[0]].forEach(val => {
          if (obj[filterArr[0]].includes(val)) {
            firstCount++;
          }
        })
        this.roleFilterData[filterArr[1]].forEach(val => {
          if (obj[filterArr[1]].includes(val)) {
            secondCount++;
          }
        })
        this.roleFilterData[filterArr[2]].forEach(val => {
          if (obj[filterArr[2]].includes(val)) {
            thirdCount++;
          }
        })
        if (firstCount == this.roleFilterData[filterArr[0]].length && secondCount == this.roleFilterData[filterArr[1]].length && thirdCount == this.roleFilterData[filterArr[1]].length) {
          newArr.push(obj)
        }
      } else if (filterArr.length == 4) {
        var firstCount = 0, secondCount = 0, thirdCount = 0, fourthCount = 0;
        this.roleFilterData[filterArr[0]].forEach(val => {
          if (obj[filterArr[0]].includes(val)) {
            firstCount++;
          }
        })
        this.roleFilterData[filterArr[1]].forEach(val => {
          if (obj[filterArr[1]].includes(val)) {
            secondCount++;
          }
        })
        this.roleFilterData[filterArr[2]].forEach(val => {
          if (obj[filterArr[2]].includes(val)) {
            thirdCount++;
          }
        })
        this.roleFilterData[filterArr[3]].forEach(val => {
          if (obj[filterArr[3]].includes(val)) {
            fourthCount++;
          }
        })
        if (firstCount == this.roleFilterData[filterArr[0]].length && secondCount == this.roleFilterData[filterArr[1]].length && thirdCount == this.roleFilterData[filterArr[2]].length && fourthCount == this.roleFilterData[filterArr[3]].length) {
          newArr.push(obj)
        }
      } else if (filterArr.length == 5) {
        var firstCount = 0, secondCount = 0, thirdCount = 0, fourthCount = 0, fifthCount = 0;
        this.roleFilterData[filterArr[0]].forEach(val => {
          if (obj[filterArr[0]].includes(val)) {
            firstCount++;
          }
        })
        this.roleFilterData[filterArr[1]].forEach(val => {
          if (obj[filterArr[1]].includes(val)) {
            secondCount++;
          }
        })
        this.roleFilterData[filterArr[2]].forEach(val => {
          if (obj[filterArr[2]].includes(val)) {
            thirdCount++;
          }
        })
        this.roleFilterData[filterArr[3]].forEach(val => {
          if (obj[filterArr[3]].includes(val)) {
            fourthCount++;
          }
        })
        this.roleFilterData[filterArr[4]].forEach(val => {
          if (obj[filterArr[4]].includes(val)) {
            fifthCount++;
          }
        })
        if (firstCount == this.roleFilterData[filterArr[0]].length && secondCount == this.roleFilterData[filterArr[1]].length && thirdCount == this.roleFilterData[filterArr[2]].length && fourthCount == this.roleFilterData[filterArr[3]].length && fifthCount == this.roleFilterData[filterArr[4]].length) {
          newArr.push(obj)
        }
      } else if (filterArr.length == 6) {
        var firstCount = 0, secondCount = 0, thirdCount = 0, fourthCount = 0, fifthCount = 0, sixthCount = 0;
        this.roleFilterData[filterArr[0]].forEach(val => {
          if (obj[filterArr[0]].includes(val)) {
            firstCount++;
          }
        })
        this.roleFilterData[filterArr[1]].forEach(val => {
          if (obj[filterArr[1]].includes(val)) {
            secondCount++;
          }
        })
        this.roleFilterData[filterArr[2]].forEach(val => {
          if (obj[filterArr[2]].includes(val)) {
            thirdCount++;
          }
        })
        this.roleFilterData[filterArr[3]].forEach(val => {
          if (obj[filterArr[3]].includes(val)) {
            fourthCount++;
          }
        })
        this.roleFilterData[filterArr[4]].forEach(val => {
          if (obj[filterArr[4]].includes(val)) {
            fifthCount++;
          }
        })
        this.roleFilterData[filterArr[5]].forEach(val => {
          if (obj[filterArr[5]].includes(val)) {
            sixthCount++;
          }
        })
        if (firstCount == this.roleFilterData[filterArr[0]].length && secondCount == this.roleFilterData[filterArr[1]].length && thirdCount == this.roleFilterData[filterArr[2]].length && fourthCount == this.roleFilterData[filterArr[3]].length && fifthCount == this.roleFilterData[filterArr[4]].length && sixthCount == this.roleFilterData[filterArr[5]].length) {
          newArr.push(obj)
        }
      } else {
        this.searchBarData = ''
        newArr = copyOfAllData
      }
    })
    this.searchBarData = ''
    this.finaldata = []
    this.finaldata = newArr

  }

  // clear data after using filter functionlaity
  clearFilter() {
    this.roleFilterData = {
      customer_name: '',
      user_name: '',
      country: '',
      city: '',
      site: '',
      room: ''
    };
    this.searchBarData = ''
    this.finaldata = copyOfAllData;
  }

  //manage delete role

  deletRole() {
    let data = {
      "function_name": "delete_role",
      "id": this.deleteId,
    }
    this.service.postApi("/delete-role", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.service.success(res['body']['responseMessage'])
        this.getRoleApi()
      } else {
        this.service.error(res['body']['responseMessage'])
      }
    }, (err) => {
    })
  }

  //manage delete modal
  deleteModal(val) {
    this.deleteId = val
  }

  suspendModal(susId, susVal) {
    this.suspendId = susId
    this.suspendVal = susVal
  }


  UnsuspendModal(susId, susVal) {
    this.unsuspendId = susId
    this.unsuspendVal = susVal

  }
  //manage unsuspendrole
  unsuspendRole() {
    let data =
    {
      "function_name": "suspend_role",
      "id": this.unsuspendId,
      "suspend": "0",
    }
    this.service.postApi("/suspend-role", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.getRoleApi()
        this.service.success(res['body']['responseMessage'])
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })

  }

  //mange suspend role 
  suspendRole() {
    let data =
    {
      "function_name": "suspend_role",
      "id": this.suspendId,
      "suspend": "1",
    }
    this.service.postApi("/suspend-role", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.getRoleApi()
        this.service.success(res['body']['responseMessage'])
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })
  }

  //navigate to edit role
  goEditRole(val) {
    this.router.navigate(['/edit-role', val])
  }

  /************************************************************************************ Pagination functionality **************************************************************************** */

  pagination(pageNo) {
    this.currentPage = pageNo
  }

  /************************************************************************************ TABLE SORTING **************************************************************************** */
  compareASC(property) {
    var sortOrder = 1;
    var newProperty;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property == 'room' || property == 'unit_name' || property == 'SN') {
        newProperty = 'newVal'
        var result = (a[newProperty] < b[newProperty]) ? -1 : (a[newProperty] > b[newProperty]) ? 1 : 0;
      } else if (property == 'customer_name' || property == 'user_name' || property == 'country' || property == 'city' || property == 'site') {
        newProperty = 'newVal'
        var result = (a[newProperty].toLowerCase() < b[newProperty].toLowerCase()) ? -1 : (a[newProperty].toLowerCase() > b[newProperty].toLowerCase()) ? 1 : 0;
      } else {
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
      }
      return result * sortOrder;
    }
  }

  compareDSC(property) {
    var sortOrder = 1;
    var newProperty;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      if (property == 'room' || property == 'unit_name' || property == 'SN') {
        newProperty = 'newVal'
        var result = (a[newProperty] > b[newProperty]) ? -1 : (a[newProperty] < b[newProperty]) ? 1 : 0;
      } else if (property == 'customer_name' || property == 'user_name' || property == 'country' || property == 'city' || property == 'site') {
        newProperty = 'newVal'
        var result = (a[newProperty].toLowerCase() > b[newProperty].toLowerCase()) ? -1 : (a[newProperty].toLowerCase() < b[newProperty].toLowerCase()) ? 1 : 0;
      } else {
        var result = (a[property].toLowerCase() > b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() < b[property].toLowerCase()) ? 1 : 0;
      }
      return result * sortOrder;
    }
  }

  sortList(sortingKey) {
    if (this.toggle == true) {
      if (sortingKey == 'role_name') {
        this.finaldata.sort(this.compareASC(sortingKey));
      } else {
        this.finaldata.forEach(obj => {
          if (obj[sortingKey].length) {
            obj[sortingKey].sort((a, b) => { if (sortingKey == 'room' || sortingKey == 'unit_name' || sortingKey == 'SN') { return a.localeCompare(b); } else { return a.toLowerCase().localeCompare(b.toLowerCase()); } })
            obj.newVal = obj[sortingKey][0]
          } else {
            obj.newVal = ''
          }
        });
        this.finaldata.sort(this.compareASC(sortingKey));
      }
      this.currentPage = 1;
      this.toggle = false
    } else {
      if (sortingKey == 'role_name') {
        this.finaldata.sort(this.compareDSC(sortingKey));
      } else {
        this.finaldata.forEach(obj => {
          if (obj[sortingKey].length) {
            obj[sortingKey].sort((a, b) => { if (sortingKey == 'room' || sortingKey == 'unit_name' || sortingKey == 'SN') { return b.localeCompare(a); } else { return b.toLowerCase().localeCompare(a.toLowerCase()); } })
            obj.newVal = obj[sortingKey][0]
          } else {
            obj.newVal = ''
          }
        });
        this.finaldata.sort(this.compareDSC(sortingKey));
      }
      this.currentPage = 1;
      this.toggle = true
    }
  }
  /***************************************************************************** TABLE SORTING END *********************************************************************** */
}
