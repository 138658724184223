import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/provider/user-data.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  title: any;
  description: any;
  id: any;
  constructor(private service: UserDataService) { }

  ngOnInit() {
    this.getContactUsContent();
  }

  //function of Get contact-us content
  getContactUsContent() {
    let data = {
      "function_name": "get_contact_us"
    }
    this.service.postApi("/get-contact-us", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.description = res['body']['body'][0]['description']
      } else {
        this.service.error("Api Response Error")
      }
    }, (err) => {
    })
  }
}
