import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserDataService } from 'src/app/provider/user-data.service';

@Component({
  selector: 'app-role-detail',
  templateUrl: './role-detail.component.html',
  styleUrls: ['./role-detail.component.css']
})
export class RoleDetailComponent implements OnInit {
  permission: any;
  demo: any;
  id: any;
  edieRoleData: any = [];
  finalRoleData: any = [];
  is_read_write = false
  is_app_alert = false
  is_email_alert = false

  constructor(private route: ActivatedRoute, private service: UserDataService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.id = params.val
    })
    this.getRoleDetail();
  }

  //manage get role details data
  getRoleDetail() {
    let data = {
      "function_name": "get_user_role_detail",
      "id": this.id
    }
    this.service.postApi("/get-user-role-detail", data).subscribe((res) => {
      if (res['body']['statusCode'] == 200) {
        this.edieRoleData = res['body']['body']
     
     
        var finalArray = [];
        for (var i in this.edieRoleData) {
          var obj = {};
          for (var key in this.edieRoleData[i]) {
            if (typeof this.edieRoleData[i][key] == 'object') {
              obj[key] = [];
              for (var innerKey in this.edieRoleData[i][key]) {
                obj[key].push(innerKey)
              }
            } else {
              obj[key] = this.edieRoleData[i][key]
            }
          }
          finalArray.push(obj)
          this.finalRoleData = finalArray
          this.permission = finalArray[0].is_permission

          this.demo = finalArray[0].is_app_alert
          this.is_email_alert = finalArray[0].is_email_alert == true ? true : false
          this.is_read_write = finalArray[0].rw  == '2' ? true : false
          this.is_app_alert = finalArray[0].is_app_alert == true ? true : false        
        }
      } else {
        this.service.error("Something Went Wrong")
      }
    }, (err) => {
    })
  }

}
